import React, { useEffect } from "react";
import { PageWrapper } from "../containers";
import { fileFolderUploadInitialState, updateProject, updateTopicId } from "../store";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ProjectsContainer from "../containers/projects/ProjectsContainer";

const Projects = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateTopicId(-1));
    dispatch(updateProject(fileFolderUploadInitialState.project));
  }, [projectId]);

  return <PageWrapper pageContainerChildren={<ProjectsContainer />} />;
};

export default Projects;
