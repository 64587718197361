import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MODAL_TYPE } from "../../utils";
import DeleteConfirmation from "./delete-confirmation";
import { EditProfile } from "./edit-profile";
import ForgotPassword from "./forgot-password";
import AddAndSignUpUser from "./add-and-sign-up-user";
import ApproveConfirmation from "./approve-confirmation";
import FeedbackForm from "./feedback";
import CreateFolder from "./add-edit-folder";
import ViewCompleteSource from "./view-complete-source";
import DataSource from "./data-source";
import EditFile from "./edit-file";
import AddProject from "./add-project";
import NoProjectsWarning from "./no-projects-warning";
import LicenseKey from "./license-key";
import ConnectToDataSource from "./connect-to-data-source";
import EditProjects from "./edit-projects";
import SourceConfirmation from "./source-confirmation";

export const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.ui);

  return (
    <>
      {modal[MODAL_TYPE.EDIT_PROFILE] && <EditProfile />}
      {modal[MODAL_TYPE.DELETE_CONFIRMATION] && <DeleteConfirmation />}
      {modal[MODAL_TYPE.FORGOT_PASSWORD] && <ForgotPassword />}
      {modal[MODAL_TYPE.ADD_AND_SIGN_UP_USER] && <AddAndSignUpUser />}
      {modal[MODAL_TYPE.APPROVE_CONFIRMATION] && <ApproveConfirmation />}
      {modal[MODAL_TYPE.FEEDBACK] && <FeedbackForm />}
      {modal[MODAL_TYPE.ADD_EDIT_FOLDER] && <CreateFolder />}
      {modal[MODAL_TYPE.VIEW_FULL_SOURCE] && <ViewCompleteSource />}
      {modal[MODAL_TYPE.DATA_SOURCE] && <DataSource />}
      {modal[MODAL_TYPE.EDIT_FILE] && <EditFile />}
      {modal[MODAL_TYPE.ADD_PROJECT] && <AddProject />}
      {modal[MODAL_TYPE.NO_PROJECTS_WARNING] && <NoProjectsWarning />}
      {modal[MODAL_TYPE.LICENSE_KEY] && <LicenseKey />}
      {modal[MODAL_TYPE.CONNECT_TO_DATA_SOURCE] && <ConnectToDataSource />}
      {modal[MODAL_TYPE.EDIT_PROJECTS] && <EditProjects />}
      {modal[MODAL_TYPE.SOURCE_CONFIRMATION] && <SourceConfirmation />}
    </>
  );
};
