import React, { useEffect } from "react";
import { Checkbox } from "antd";
import { TopicsDropdown } from "../../../components";
import { InformationIcon } from "../../../assets/icons";
import { METADATA_FILTER_DESCRIPTION } from "../../../utils";
import { useDispatch } from "react-redux";
import { setQuestionsAnswers } from "../../../store";
import Tooltip from "antd/es/tooltip";

interface Props {
  selectedFile: string;
  fileterOnMetadata: boolean;
  selectedTopic: IDropdownOption;
  setSelectedTopic: React.Dispatch<React.SetStateAction<IDropdownOption>>;
  setSelectedFile: React.Dispatch<React.SetStateAction<string>>;
  setFilterOnMetadata: React.Dispatch<React.SetStateAction<boolean>>;
  handleProjectChange: (e: string, option: IDropdownOption, projects: ITopic[]) => void;
}

export const ChatFilters = ({
  selectedFile,
  setSelectedFile,
  fileterOnMetadata,
  setFilterOnMetadata,
  selectedTopic,
  setSelectedTopic,
  handleProjectChange,
}: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setQuestionsAnswers([]));
  }, [dispatch, selectedTopic]);

  return (
    <div className="project-select">
      <TopicsDropdown {...{ selectedTopic, setSelectedTopic, handleProjectChange }} />
      {/* <Checkbox checked={fileterOnMetadata} onChange={(e) => setFilterOnMetadata(e.target.checked)}>
        <div className="flex font-12 aic no-wrap gp-10">
          <span>Filter On Metadata</span>
          <Tooltip placement="bottom" title={METADATA_FILTER_DESCRIPTION}>
            <span>
              <InformationIcon />
            </span>
          </Tooltip>
        </div>
      </Checkbox> */}
    </div>
  );
};
