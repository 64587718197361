import { apiClient } from "./apiClient";

interface IGetUsersResponse {
  data: {
    users: IUser[];
    paging: IPagination;
  };
  status: string;
}

interface IApproveUserResponse {
  data: string;
  status: string;
}

const approveUser = async (userId: number) => {
  const res = await apiClient.put<IApproveUserResponse>(`/auth/approve-user/${userId}`);
  return res;
};

const getUsers = async (qs: string) => {
  const res = await apiClient.get<IGetUsersResponse>(`/user/list${qs}`);
  return res;
};

export const userService = {
  approveUser,
  getUsers,
};
