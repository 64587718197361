import React from "react";
import { IconsProps } from "./Icons";

export const CautionIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 48 43"}
      width={width ? width : "48"}
      height={height ? height : "43"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M47.3142 35.1605L28.5758 2.61858C28.1076 1.82132 27.4391 1.16026 26.6367 0.700949C25.8342 0.241633 24.9257 0 24.0011 0C23.0765 0 22.1679 0.241633 21.3655 0.700949C20.563 1.16026 19.8946 1.82132 19.4263 2.61858L0.687978 35.1605C0.237435 35.9316 0 36.8087 0 37.7018C0 38.5949 0.237435 39.4719 0.687978 40.2431C1.15023 41.0452 1.81757 41.7098 2.62151 42.1688C3.42545 42.6278 4.33704 42.8647 5.26274 42.8551H42.7394C43.6644 42.8639 44.5751 42.6267 45.3782 42.1677C46.1813 41.7088 46.848 41.0445 47.3099 40.2431C47.7611 39.4723 47.9992 38.5955 48 37.7024C48.0007 36.8093 47.7641 35.932 47.3142 35.1605ZM22.2869 17.1421C22.2869 16.6875 22.4675 16.2515 22.7889 15.93C23.1104 15.6085 23.5464 15.4279 24.0011 15.4279C24.4557 15.4279 24.8917 15.6085 25.2132 15.93C25.5347 16.2515 25.7153 16.6875 25.7153 17.1421V25.7131C25.7153 26.1677 25.5347 26.6038 25.2132 26.9252C24.8917 27.2467 24.4557 27.4273 24.0011 27.4273C23.5464 27.4273 23.1104 27.2467 22.7889 26.9252C22.4675 26.6038 22.2869 26.1677 22.2869 25.7131V17.1421ZM24.0011 35.9983C23.4925 35.9983 22.9954 35.8475 22.5725 35.565C22.1497 35.2824 21.8201 34.8808 21.6255 34.411C21.4309 33.9411 21.38 33.4241 21.4792 32.9254C21.5784 32.4266 21.8233 31.9684 22.1829 31.6088C22.5425 31.2492 23.0007 31.0043 23.4994 30.9051C23.9982 30.8059 24.5152 30.8568 24.9851 31.0514C25.4549 31.246 25.8565 31.5756 26.139 31.9985C26.4216 32.4213 26.5724 32.9184 26.5724 33.427C26.5724 34.1089 26.3015 34.763 25.8192 35.2452C25.337 35.7274 24.683 35.9983 24.0011 35.9983Z"
        fill="#BB4545"
      />
    </svg>
  );
};
