import React, { useEffect, useMemo, useState } from "react";
import { ISources, uploadFileFolderService } from "../../../api";
import { Loading } from "../../../components";
import { useSelector } from "react-redux";
import { RootState, openModal, setCurrentSource } from "../../../store";
import { MODAL_TYPE, STATUS_CODE, downloadPDF, openNotificationWithIcon } from "../../../utils";
import "../chat-container/chat-ques-ans.scss";
import { useAuthorization } from "../../../hooks";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

interface Props {
  chatSource: ISources;
  setHideChatHistory: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrPage?: (page: number) => void;
}

export const ChatSource = ({ chatSource, setHideChatHistory, setExpanded, setCurrPage }: Props) => {
  
  const { documentUUID, pages } = chatSource;
  const [currentPage, setCurrentPage] = useState(pages[0]);
  const [currentSourceContent, setCurrentSourceContent] = useState("");
  const [isSourceContentLaoding, setIsSourceContentLoading] = useState(false);
  const [isApiCall, setIsApiCall] = useState(true);
  const { setIsAuthorized } = useAuthorization();
  const [fileUrl, setFileUrl] = useState("");
  const [isTextFile, setIsTextFile] = useState(false);
  const [isDocFile, setIsDocFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { currPage } = useSelector((state: RootState) => state.ui);

  useEffect(() => {
    setCurrentPage(pages[0]);
    setCurrPage && setCurrPage(pages[0].pageNo);
    setIsApiCall(true);
  }, [pages]);

  const highlightedSourceContent = useMemo(() => {
    let content = currentSourceContent;
    const terms = currentPage.offsets.map((offset) =>
      currentSourceContent.substring(offset.begin, offset.end)
    );
    if(pages.map(({ pageNo }) => pageNo).includes(currPage)) {
      terms.forEach((term) => {
        const html = `<span class="bg-yellow">${term}</span>`;
        content = content.replace(term, html);
      });
      content = content.replaceAll(`\n`, `<br/>`);
    }
    return content;
  }, [currentPage.offsets, currentSourceContent]);

  const fetchSourceContent = async (page: number) => {
    setIsSourceContentLoading(true);
    try {
      const { data } = await uploadFileFolderService.getFileOCR({
        fileUuid: documentUUID,
        pageNum: page,
      });
      setCurrentSourceContent(data.data.ocr_text);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        console.error(err.response.data.message);
        openNotificationWithIcon("", "Document not found", "error");
        setHideChatHistory(false);
        setExpanded(false);
      }
    } finally {
      setIsSourceContentLoading(false);
    }
  };

  useEffect(() => {
    if (isApiCall) {
      fetchSourceContent(currentPage.pageNo);
      setIsApiCall(false);
    }
  }, [currentPage, documentUUID, isApiCall, setIsAuthorized]);

  useEffect(() => {
    const getSignedurl = async () => {
      setIsLoading(true);
      try {
        const { data } = await uploadFileFolderService.getSignedURLForFullSource(
          chatSource.documentUUID
        );
        const filepath = data.data.filePath;
        setIsTextFile(filepath.endsWith(".txt"));
        setIsDocFile(filepath.endsWith(".doc") || filepath.endsWith(".docx"));
        setFileUrl(data.data.signedUrl);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        }
      } finally {
        setIsLoading(false);
      }
    };
    getSignedurl();
  }, [documentUUID, isDocFile]);

  const handleFileDownload = async () => {
    const extension = isDocFile ? "docx" : isTextFile ? "txt" : "pdf";
    await downloadPDF(fileUrl, `source_${new Date().getTime()}.${extension}`);
  };

  const getDistinctPages = useMemo(() => {
    const uniqueUUIDs = new Set();
    return pages.filter((page) => {
      if (!uniqueUUIDs.has(page.chunkUUID)) {
        uniqueUUIDs.add(page.chunkUUID);
        return true;
      }
      return false;
    });
  }, [pages]);

  useEffect(() => {
    fetchSourceContent(currPage);
  },[currPage])

  return (
    <>
      <div className="m-b">
        <h5>Results in: </h5>
        <ul className="source-list grid grid-cols-3 gp-10">
          {getDistinctPages.map((item, index) => (
            <li
              key={index}
              className={`cursor-pointer ${(currPage === item.pageNo && "active") || ""}`}
              onClick={() => {
                setCurrentPage(item);
                setCurrPage && setCurrPage(item.pageNo);
                setIsApiCall(true);
                // onPaginationChange(page.pageNo,pageSize);
              }}
            >
              {`Page ${item.pageNo}`}
            </li>
          ))}
        </ul>
      </div>
      <div className="source-content">
        {/* <h5>Source Content</h5> */}
        {isSourceContentLaoding ? (
          <Loading />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: highlightedSourceContent }}></div>
        )}
        <Button
          className="outline download-button"
          onClick={handleFileDownload}
          icon={<DownloadOutlined />}
          style={{ width: "100px", marginBottom: 25 }}
        >
          Download
        </Button>
      </div>
    </>
  );
};
