import { Dispatch } from "redux";
import { IDirectoryPath, RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import { DataSource } from "./DataSource";

type StateProps = {
  visibility: boolean;
  fileName: string;
  directoryPath: IDirectoryPath;
  topicId: number;
  storage?: string;
};

type DispatchProps = {
  handleClose: () => void;
};

export type DataSourceProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.DATA_SOURCE],
  fileName: state.fileFolderUpload.targetFileFolder,
  directoryPath: state.fileFolderUpload.directoryPath,
  topicId: state.fileFolderUpload.topicId,
  storage: state.fileFolderUpload.storage
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.DATA_SOURCE));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSource);
