import React, { useEffect, useState } from "react";
import { EditProjectsProps } from ".";
import { Button, Input, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { InformationIcon } from "../../../assets/icons";
import {
  DATA_EMBEDDING_MODELS_DESCRIPTION,
  STATUS_CODE,
  openNotificationWithIcon,
} from "../../../utils";
import { Dropdown } from "../../../components";
import { TopicsService, SettingsService, ProjectsService } from "../../../api";
import { useProject } from "../../../hooks";
import { useSelector } from "react-redux";
import { RootState, fileFolderUploadInitialState, updateSelectedProject } from "../../../store";

export const EditProjects = ({
  handleClose,
  visibility,
  handleCanFetchProjects,
}: EditProjectsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const temp = useSelector((state: RootState) => state.fileFolderUpload);
  const [projectName, setProjectName] = useState(
    temp.selectedProjects.displayName
  );

  const handleEditProject = async () => {
    setIsLoading(true);
    try {
      await ProjectsService.editProjects(temp.selectedProjects.uuid, projectName);
      openNotificationWithIcon("", "Project name updated successfully", "success");
      handleCanFetchProjects();
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1>{"Edit Project"}</h1>
      </div>
      <div className="addClient-content modal-form-content">
        <div className="single-content">
          <p className="font-Semibold">Project Name : </p>
          <Input value={projectName} onChange={(e) => setProjectName(e.target.value)} />
        </div>
        <div className="single-content m-t flex aic gp-10 jcsb">
          <Button
            className="fill"
            onClick={handleEditProject}
            disabled={projectName === ""}
            loading={isLoading}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};
