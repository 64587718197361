import React, { useCallback, useEffect, useState } from "react";
import { ChunkingStrategy, DataEmbedding } from "./document-ingestion-settings-container";
import { useFetchDocumentIngestionSettings, useProjectSettings } from "../../../hooks";
import { EditSettingsButton } from "./EditSettingsButton";
import { CautionIcon } from "../../../assets/icons";

const ERROR_MSG =
  "Some documents have already been processed with the specified settings. To ingest documents with different settings, please delete this project and create a new one.";

interface Props {
  ingestionSetting: IProjectDocumentIngestionSetting;
  projectId: string;
  hasDocument?: boolean;
}

export const DocumentIngestionSettings = (props: Props) => {
  const { ingestionSetting, projectId, hasDocument } = props;
  const [isEdit, setIsEdit] = useState(false);
  const {
    chunkSizes,
    embeddingModels,
    getDropdownsData,
    isDropdownsDataLoading,
    tokenizers,
    selectedChunkSize,
    selectedEmbeddingModel,
    selectedTokenizer,
    setSelectedChunkSize,
    setSelectedEmbeddingModel,
    setSelectedTokenizer,
  } = useFetchDocumentIngestionSettings();
  const { updateDocumentIngestionSettings, updateSettingLoading } = useProjectSettings();
  const [error, setError] = useState("");

  const setParams = useCallback(async () => {
    if (Object.keys(ingestionSetting).length) {
      const { chunkSize, dataEmbedding, chunkingStrategy } = ingestionSetting;
      await getDropdownsData(chunkSize, chunkingStrategy, dataEmbedding);
    }
  }, [getDropdownsData, ingestionSetting]);

  useEffect(() => {
    setParams();
    if (hasDocument) setError(ERROR_MSG);
  }, [hasDocument, setParams]);

  const handleCancelClick = async () => {
    setIsEdit(!isEdit);
    await setParams();
  };

  const handleEditClick = async () => {
    if (isEdit && projectId) {
      const reqObj: UpdateProjectIngestionSettingRequest = {
        chunkSizeId: selectedChunkSize.id,
        chunkingStrategyId: selectedTokenizer.id,
        dataEmbeddingId: selectedEmbeddingModel.id,
      };
      await updateDocumentIngestionSettings(projectId, reqObj);
    }
    setIsEdit(!isEdit);
  };

  return (
    <div>
      <div className="grid grid-cols-2 document-ingestion">
        <ChunkingStrategy
          {...{
            chunkSizes,
            selectedChunkSize,
            setSelectedChunkSize,
            tokenizers,
            selectedTokenizer,
            setSelectedTokenizer,
            isDropdownsDataLoading,
          }}
          isDisabled={!isEdit}
        />
        <DataEmbedding
          {...{
            embeddingModels,
            selectedEmbeddingModel,
            setSelectedEmbeddingModel,
            isDropdownsDataLoading,
          }}
          isDisabled={true}
        />
      </div>
      <EditSettingsButton
        {...{
          handleEdit: handleEditClick,
          isEdit,
          setIsEdit,
          disabled: hasDocument,
          loading: updateSettingLoading,
          handleCancel: handleCancelClick,
        }}
      />
    </div>
  );
};
