import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { EditIcon } from "../../../assets/icons";

interface Props {
  disabled?: boolean;
  loading?: boolean;
  handleEdit: () => void;
  handleCancel: () => void;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditSettingsButton = (props: Props) => {
  const { handleEdit, loading, isEdit, setIsEdit, disabled, handleCancel } = props;

  return (
    <div className="flex jce m-t gp">
      {isEdit && (
        <Button className="outline flex gp-10 aic lh-1" onClick={() => handleCancel()}>
          Cancel
        </Button>
      )}
      <Button
        className="outline flex gp-10 aic lh-1"
        loading={loading}
        disabled={disabled}
        icon={isEdit ? <CheckOutlined /> : <EditIcon />}
        onClick={() => handleEdit()}
      >
        {isEdit ? "Update Settings" : "Edit"}
      </Button>
    </div>
  );
};
