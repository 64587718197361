import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { AppHeader, AppMenu, ILoadingPrivateLayout, PageHeader } from "../containers";
import { useAuthUser } from "../hooks";
import { PAGE_URL } from "../utils";
import ProjectSubHeader from "../containers/app-containers/app-project-sub-heading/ProjectSubHeader";

const initialPageHeader = { pageTitle: "", displayBackButton: false, pageRoute: "" };
const initialLoading = { isPage: true };

const loadingKeys: { [key: string]: keyof ILoadingPrivateLayout } = { isPage: "isPage" };

export const PrivateLayout = () => {
  const { accessToken } = useAuthUser();
  if (!accessToken) window.location.replace(PAGE_URL.LOGIN);

  const [pageHeader, setPageHeader] = useState<PageHeader>(initialPageHeader);
  const [loading, setLoading] = useState<ILoadingPrivateLayout>(initialLoading);

  const resetPageHeader = () => {
    setPageHeader(initialPageHeader);
  };

  const setLoadingByKey = (
    key: keyof typeof initialLoading,
    value: boolean | undefined = undefined
  ) => setLoading((prev) => ({ ...prev, [key]: value !== undefined ? value : !prev[key] }));

  return (
    <Layout>
      <AppMenu />
      <Layout>
        <AppHeader pageHeader={pageHeader} />
        <ProjectSubHeader />
        <Outlet
          context={{
            pageHeader,
            setPageHeader,
            resetPageHeader,
            loading,
            setLoadingByKey,
            loadingKeys,
          }}
        />
        {/* <AppFooter /> */}
      </Layout>
    </Layout>
  );
};

export interface IOutletContext {
  setPageHeader: (header: PageHeader) => void;
  pageHeader: PageHeader;
  resetPageHeader: () => void;
  loading: typeof initialLoading;
  setLoadingByKey: (key: keyof typeof initialLoading, value?: boolean) => void;
  loadingKeys: typeof loadingKeys;
}
