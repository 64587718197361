import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

interface UserListDataType extends IUser {
  key: React.Key;
}

const getColumns = (): ColumnsType<UserListDataType> => [
  {
    title: "First Name",
    dataIndex: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
];

interface Props {
  isLoading: boolean;
  users: IUser[];
}

export const ApprovedUsers = (props: Props) => {
  const { isLoading, users } = props;

  const usersData: UserListDataType[] = users.map((user) => ({
    key: `approved-user-${user.id}`,
    ...user,
  }));

  return (
    <>
      <Table
        className="basic-detail-content m-t user-table-mh"
        columns={getColumns()}
        dataSource={usersData}
        loading={isLoading}
        pagination={false}
      />
    </>
  );
};
