import React, { useCallback, useEffect, useState } from "react";
import { ChatFilters, ChatQuesAns } from "./chat-container";
import { ISources } from "../../api";
import { DrawerIcon } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState, resetQuestionAnswerState, updateProjectType } from "../../store";
import ExpandToggleIcon from "../../components/expand-toggle-icon/ExpandToggleIcon";
import { WarningOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { useProject } from "../../hooks";
import "./chat-container/chat-ques-ans.scss"
interface Props {
  setChatSource: React.Dispatch<React.SetStateAction<ISources>>;
  isQuesAnsLoading: boolean;
  setIsQuesAnsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setHideChatHistory: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  hideChatHistory: boolean;
  setShowChatAside: React.Dispatch<React.SetStateAction<boolean>>;
  showChatAside: boolean;
}

export const ChatContainer = ({
  isQuesAnsLoading,
  setChatSource,
  setIsQuesAnsLoading,
  setHideChatHistory,
  expanded,
  hideChatHistory,
  setExpanded,
  setShowChatAside,
  showChatAside,
}: Props) => {
  const [selectedFile, setSelectedFile] = useState("All");
  const [showHelp, setShowHelp] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({
    id: -1,
    value: "",
  } as IDropdownOption);
  const dispatch = useDispatch();
  const { fetchTopics, topics } = useProject();
  const [showAlert, setShowAlert] = useState(false);
  const { project: selectedProject , topicId } = useSelector((state: RootState) => state.fileFolderUpload);
  
  const handleProjectChange = useCallback(
    (e: string, option: IDropdownOption, projects: ITopic[]) => {
      setSelectedTopic(option);
      const projectType = projects.find((project) => project.id === option.id)?.projectType;
      dispatch(updateProjectType(projectType || "default"))
    },
    [dispatch]
  );

  const [fileterOnMetadata, setFilterOnMetadata] = useState(false);

  const closeCard = () => {
    setShowHelp(false);
  };

  useEffect(() => {
    return () => {
      dispatch(resetQuestionAnswerState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedProject.sourceName) {
      setShowAlert(true);
    } else setShowAlert(false)
  }, [selectedProject]);

  return (
    <>
      <div className="qa-container">
        <ExpandToggleIcon
          expanded={hideChatHistory ? expanded : showChatAside}
          setExpanded={hideChatHistory ? setExpanded : setShowChatAside}
          RTL
        />
        <div className="d-flex jcsb gp">
          <ChatFilters
            {...{
              selectedFile,
              setSelectedFile,
              fileterOnMetadata,
              setFilterOnMetadata,
              handleProjectChange,
              selectedTopic,
              setSelectedTopic
            }}
          />
          {showAlert && (
            <div className="alert-center">
              <Alert
                icon={<WarningOutlined />}
                message="No data source has been selected, please select a data source before asking QA."
                type="error"
                showIcon
              />
            </div>
          )}
          {showHelp && (
            <div className="card-container">
              <div className="card">
                <p style={{ fontSize: "12px" }}>
                  Welcome to Ask Auto ChatBOT! Ask Auto can help you find the information you need,
                  faster than ever before. Ask Auto is currently configured to answer questions
                  from:
                </p>
                <ol>
                  {selectedTopic.value.toLowerCase().includes("pi") ? (
                    <>
                      <li>1. Payment Integrity Reference documents</li>
                      <li>2. PI - White Papers and CMS guidelines</li>
                    </>
                  ) : (
                    <>
                      <li>1. Claims SOP Documents</li>
                      <li>2. Clinical Policies</li>
                      <li>3. UM Policies</li>
                      <li>4. Pharmacy Policies</li>
                      <li>5. Enrollment Process documents</li>
                    </>
                  )}
                </ol>
              </div>
              <div className="close-button" onClick={() => closeCard()}>
                X
              </div>

              {/* <h5>If you’d like to look at the knowledge base - please access the link here - \\molina.mhc\mhroot\CorpIS\OM\MorlaRam\AIML\All
               *Not all documents may be available - please refer to the knowledge base”</h5> */}
            </div>
          )}
        </div>

        <ChatQuesAns
          {...{
            isQuesAnsLoading,
            setChatSource,
            setIsQuesAnsLoading,
            selectedFile,
            fileterOnMetadata,
            setHideChatHistory,
            topicId,
            showHelp,
            setShowHelp,
            setShowChatAside,
            isSingleDoc: false,
          }}
        />
      </div>
    </>
  );
};
