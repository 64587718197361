import React, { useState } from "react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import "./qaconfiguration.scss";
import { PROJECT_TYPE_SETTINGS, openNotificationWithIcon } from "../../../../utils";
import { SettingsService } from "../../../../api";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

interface QandAConfigurationProps {
  qaConfiguration: {
    dataFields?: Field[];
    projectPrompts?: PreConfiguredField[];
  };
  isLoading: boolean;
  handleBack?: () => void;
}

const preConfiguredfields = [
  { id: 1, question: "" },
  { id: 2, question: "" },
  { id: 3, question: "" },
  { id: 4, question: "" },
];

export const QandAConfiguration = ({ qaConfiguration, isLoading }: QandAConfigurationProps) => {
  const { projectId } = useParams();
  const { handleSubmit, register, control, getValues, reset, setValue } = useForm({
    defaultValues: {
      fields: qaConfiguration.dataFields || [],
      preConfiguredPrompts: qaConfiguration.projectPrompts || [],
    },
  });
  const [loadingPrompts, setLoadingPrompts] = useState<boolean>(false);
  const [loadingFields, setLoadingFields] = useState<boolean>(false);


  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const addFields = () => {
    append({ fieldName: "", fieldPrompt: "" });
  };

  const isFormChanged = (name: any) => {
    const currentValues = getValues(name);
    const initialValues = qaConfiguration[name as keyof typeof qaConfiguration];
    return JSON.stringify(currentValues) !== JSON.stringify(initialValues);
  };

  const onSubmitFields = async (payload: any, setting: string) => {
    try {
      const dataFieldsPayload = {
        dataFields: payload.fields.map((field: Field) => ({
          id: field.id,
          fieldName: field.fieldName,
          fieldPrompt: field.fieldPrompt,
        })),
      };
      const preConfiguredPromptsPayload = {
        projectPrompts: payload.preConfiguredPrompts.map(
          (prompt: PreConfiguredField, index: number) => ({
            question: prompt.question,
            id: prompt.id,
          })
        ),
      };

      let updatedFields = [];
      let updatedPrompts = [];

      if (qaConfiguration.dataFields && setting === PROJECT_TYPE_SETTINGS.DATA_FIELDS) {
        setLoadingFields(true)
        const { data } = await SettingsService.updateDataFields(dataFieldsPayload, projectId);
        updatedFields = data.data;
        reset({ fields: updatedFields, preConfiguredPrompts: getValues("preConfiguredPrompts") });
        setLoadingFields(false)
      }

      if (
        qaConfiguration.projectPrompts &&
        setting === PROJECT_TYPE_SETTINGS.PRECONFIGURED_PROMTPS
      ) {
        setLoadingPrompts(true)
        const { data } = await SettingsService.updatePreConfiguredPrompts(
          preConfiguredPromptsPayload,
          projectId
        );
        updatedPrompts = data.data;
        reset({ fields: getValues("fields"), preConfiguredPrompts: updatedPrompts });
      }
      openNotificationWithIcon("Success", "Configuration saved successfully.", "success");
      setLoadingPrompts(false);
    } catch (error) {
      console.error(error);
    }
  };

  const renderExtractDataFields = () => {
    return (
      <>
        <div className="single-doc">
          <h2>Extract Specific Data-fields</h2>
        </div>
        <div className="form-container">
          <form
            className="form-content"
            onSubmit={handleSubmit((payload) =>
              onSubmitFields(payload, PROJECT_TYPE_SETTINGS.DATA_FIELDS)
            )}
          >
            <div className="headings">
              <p>Enter Data Field Name</p>
              <p>Please enter your prompt, ensuring you specify the details of the field clearly</p>
            </div>
            {fields.map((field, index) => (
              <div key={`${index}`} className="field-row">
                <div className="input-container">
                  <input
                    {...register(`fields.${index}.fieldName`)}
                    className="wide-input2"
                    defaultValue={field.fieldName}
                  />
                </div>
                <div className="input-container">
                  <input
                    {...register(`fields.${index}.fieldPrompt`)}
                    className="wide-input2"
                    defaultValue={field.fieldPrompt}
                  />
                  <DeleteOutlined className="delete-icon" onClick={() => remove(index)} />
                </div>
              </div>
            ))}
            <div className="qa-config-footer">
              <Button className="outline" onClick={addFields}>
                + Add
              </Button>
              <Button
                htmlType="submit"
                className="fill"
                disabled={isLoading || !isFormChanged("fields")}
                loading={loadingFields}
              >
                {!loadingFields ? "Save" : "Saving"}
              </Button>
            </div>
          </form>
        </div>
      </>
    );
  };

  const renderPreconfiguredPrompts = () => {
    return (
      <>
        <div className="single-doc">
          <h2>Define Preconfigured Prompts</h2>
          <h5>
            Please enter the Predefined question visible in the QA window (maximum of 4 questions)
          </h5>
        </div>
        <div className="form-container">
          <form
            className="form-content"
            onSubmit={handleSubmit((payload) =>
              onSubmitFields(payload, PROJECT_TYPE_SETTINGS.PRECONFIGURED_PROMTPS)
            )}
          >
            <div className="field-row">
              {preConfiguredfields?.map((question, index) => (
                <div key={question.id}>
                  <div className="headings">
                    <p>Question {question.id}</p>
                  </div>
                  <div>
                    <input
                      {...register(`preConfiguredPrompts.${index}.question`)}
                      className="wide-input2"
                      defaultValue={question.question}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="qa-config-footer">
              <Button
                htmlType="submit"
                className="fill"
                disabled={isLoading || !isFormChanged("preConfiguredPrompts")}
                loading={loadingPrompts}
              >
                {!loadingPrompts ? "Save" : "Saving"}
              </Button>
            </div>
          </form>
        </div>
      </>
    );
  };

  const renderNoneConfiguration = () => {
    return (
      <div className="single-doc">
        <h4>None</h4>
      </div>
    );
  };

  const component = () => {
    const keys = Object.keys(qaConfiguration);
    if (keys.includes("dataFields") && keys.includes("projectPrompts")) {
      return (
        <>
          {renderExtractDataFields()}
          {renderPreconfiguredPrompts()}
        </>
      );
    } else if (keys.includes("dataFields")) {
      return renderExtractDataFields();
    } else if (keys.includes("projectPrompts")) {
      return renderPreconfiguredPrompts();
    } else {
      return renderNoneConfiguration();
    }
  };

  return <div className="main-container">{component()}</div>;
};
