import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import "./project-sub-heading.scss"

const ProjectSubHeader = () => {
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  return (
    <>
      {project?.displayName?.length !== 0 && (
        <div className="project-sub-heading">
          <span>
            Project: <p>{project?.displayName}</p>
          </span>
        </div>
      )}
    </>
  );
}

export default ProjectSubHeader
