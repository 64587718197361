import React from "react";
import { IGetAnswerRequest } from "../../../api";

export const SectionByDate = ({
  title,
  qaList,
  handleQuesClick,
}: {
  title: string;
  qaList: { id: string; query: IGetAnswerRequest; createdAt: Date }[];
  handleQuesClick: (logId: string) => void;
}) => {
  if (!qaList.length) return null;

  return (
    <div className="qa-section mt-10 mb-20">
      <span className="text-sm text-base font-medium">{title}</span>
      {qaList.map((qa) => (
        <span
          className="cursor-pointer chat-history-ques"
          key={`qa-history-${qa.id}`}
          onClick={() => handleQuesClick(qa.id)}
          title={qa.query.q}
        >
          {qa.query.q}
        </span>
      ))}
    </div>
  );
};
