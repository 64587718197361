import { IRepsonseWrapper } from ".";
import { IProject } from "../store";
import { apiClient } from "./apiClient";

const getProjects = async () => {
  const res = await apiClient.get("/projects");
  return res;
};

const createProjects = async (data: any) => {
  const res = await apiClient.post("/projects", data);
  return res;
};

const editProjects = async (uuid: string, name: string, fields?: Field[]) => {
  const payload = fields ? { displayName: name, fields } : { displayName: name };
  const res = await apiClient.put(`/projects/${uuid}`, payload);
  return res;
};

const deleteProjects = async(uuid: string) => {
  const res = await apiClient.delete(`/projects/${uuid}`);
  return res;
}

const getProjectsFields = async(uuid?: string) => {
  const res = await apiClient.get(`/projects/${uuid}`);
  return res;
}

export const ProjectsService = {
  getProjects,
  createProjects,
  editProjects,
  deleteProjects,
  getProjectsFields
};
