import React from "react";
import { Form } from "antd";
import { MailIcon } from "../../../assets/icons";

interface Props {
  email: string;
}

export const AddAndSignUpUserSuccess = ({ email }: Props) => {
  return (
    <>
      <div className="addClient-title">
        <h1>Success</h1>
      </div>
      <div className="login-content-modal">
        <Form className="email-confirmation">
          <MailIcon />
          <p className="user-form-message">
            Your application has been successfully recorded. Create password link will be sent to
            you on your registered email <strong>{email}</strong> after your application is
            approved.
          </p>
        </Form>
      </div>
    </>
  );
};
