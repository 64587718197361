import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useState } from "react";
import { SignUpUserProps } from ".";
import { AddAndSignUpUserForm } from "./AddAndSignUpUserForm";
import { AddAndSignUpUserSuccess } from "./AddAndSignUpUserSuccess";

export const AddAndSignUpUser = ({
  visibility,
  roleCode,
  setIsUserApproved,
  handleClose,
}: SignUpUserProps) => {
  const [email, setEmail] = useState("");

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={690}
      title=""
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      {email ? (
        <AddAndSignUpUserSuccess email={email} />
      ) : (
        <AddAndSignUpUserForm {...{ setEmail, roleCode, setIsUserApproved, handleClose }} />
      )}
    </Modal>
  );
};
