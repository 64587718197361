import React from "react";

interface Props {
  children?: JSX.Element;
  asideClassName?: string;
}

export const PageAsideLeftWrapper = ({ children, asideClassName }: Props) => {
  return <div className={`main-aside ${asideClassName ? asideClassName : ""}`}>{children}</div>;
};
