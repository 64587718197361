import React from "react";
import { ChatService, IGetAnswerRequest } from "../../../api";
import { differenceInDays, formatDate, openNotificationWithIcon } from "../../../utils";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setQuestionsAnswers } from "../../../store";
import { Loading } from "../../../components";
import { SectionByDate } from "./SectionByDate";

interface Props {
  qaHistory: { id: string; query: IGetAnswerRequest; createdAt: Date }[];
  page: number;
  pageSize: number;
  totalQA: number;
  setQAHistory: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        query: IGetAnswerRequest;
        createdAt: Date;
      }[]
    >
  >;
  setTotalQA: React.Dispatch<React.SetStateAction<number>>;
  setIsQuesAnsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onPaginationChange: (updatedPage: number, updatedPageSize: number) => void;
  fetchQAHistory: (showMore?: boolean) => Promise<void>;
  isLoading: boolean;
  setShowRightContainer?: React.Dispatch<React.SetStateAction<boolean>>;
}

const groupQAByDate = (qaHistory: { id: string; query: IGetAnswerRequest; createdAt: Date }[]) => {
  const now = new Date();
  const currentYear = now.getFullYear();

  const grouped = {
    last7Days: [] as { id: string; query: IGetAnswerRequest; createdAt: Date }[],
    last30Days: [] as { id: string; query: IGetAnswerRequest; createdAt: Date }[],
    currentYearByMonth: {} as Record<string, { id: string; query: IGetAnswerRequest; createdAt: Date }[]>,
    olderYears: {} as Record<string, { id: string; query: IGetAnswerRequest; createdAt: Date }[]>,
  };

  qaHistory.forEach((qa) => {
    const createdAt = new Date(qa.createdAt);
    const daysDifference = differenceInDays(now, createdAt);

    if (daysDifference <= 7) {
      grouped.last7Days.push(qa);
    } else if (daysDifference <= 30) {
      grouped.last30Days.push(qa);
    } else {
      const year = createdAt.getFullYear();
      if (year === currentYear) {
        const monthKey = formatDate(createdAt, "yyyy-MM");
        if (!grouped.currentYearByMonth[monthKey]) {
          grouped.currentYearByMonth[monthKey] = [];
        }
        grouped.currentYearByMonth[monthKey].push(qa);
      } else {
        const yearKey = year.toString();
        if (!grouped.olderYears[yearKey]) {
          grouped.olderYears[yearKey] = [];
        }
        grouped.olderYears[yearKey].push(qa);
      }
    }
  });

  return grouped;
};

export const ChatHistoryList = ({
  qaHistory,
  page,
  pageSize,
  setQAHistory,
  setTotalQA,
  setIsQuesAnsLoading,
  onPaginationChange,
  totalQA,
  fetchQAHistory,
  isLoading,
  setShowRightContainer,
}: Props) => {
  const dispatch = useDispatch();
  const { quesAns } = useSelector((state: RootState) => state.quesAns);

  const handleQuesClick = async (logId: string) => {
    setIsQuesAnsLoading(true);
    try {
      const { data } = await ChatService.getSingleQa({ logId: Number(logId) });
      dispatch(
        setQuestionsAnswers([
          ...quesAns,
          {
            answer: data.data.answer.answer,
            question: data.data.answer.query,
            logId: Number(data.data.id),
            sources: data.data.answer.sources,
            qaId: data.data.qaId,
          },
        ])
      );
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsQuesAnsLoading(false);
      if (setShowRightContainer) {
        setShowRightContainer(false);
      }
    }
  };

  const showMoreClick = () => {
    onPaginationChange(page + 1, pageSize);
  };

  // const groupedQA = groupQAByDate(qaHistory);

  if (!qaHistory.length) {
    return (
      <>
        {isLoading ? (
          <Loading />
        ) : (
          <p className="normal-font-size lh-100">
            No Chat history available now. Type your first question to begin the chat.
          </p>
        )}
      </>
    );
  }

  return (
    <>
      <SectionByDate title="" qaList={qaHistory} handleQuesClick={handleQuesClick} />
      {/* <SectionByDate title="Previous 7 Days" qaList={groupedQA.last7Days} handleQuesClick={handleQuesClick} />
      <SectionByDate
        title="Previous 30 Days"
        qaList={groupedQA.last30Days}
        handleQuesClick={handleQuesClick}
      />
      {Object.keys(groupedQA.currentYearByMonth).map((monthKey) => (
        <SectionByDate
          key={monthKey}
          title={formatDate(new Date(monthKey + "-01"), "MMMM")}
          qaList={groupedQA.currentYearByMonth[monthKey]}
          handleQuesClick={handleQuesClick}
        />
      ))}
      {Object.keys(groupedQA.olderYears).map((yearKey) => (
        <SectionByDate
          key={yearKey}
          title={yearKey}
          qaList={groupedQA.olderYears[yearKey]}
          handleQuesClick={handleQuesClick}
        />
      ))} */}
      {qaHistory.length !== totalQA && (
        <div className="tc">
          <Button className="outline" onClick={showMoreClick} loading={isLoading}>
            Show More
          </Button>
        </div>
      )}
    </>
  );
};
