import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import { autonomizeFullLogo, autonomizeLogo } from "../../../assets/images";
import { CollapseIcon } from "../../../assets/icons";
import "./app-menu.scss";
import { getAppMenuItems } from "./AppMenuItems";
import { useAuthUser } from "../../../hooks";
import { PAGE_TITLE } from "../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const { Sider } = Layout;

export const RedirectMenu = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const { roleCode } = useAuthUser();
  const { projectType,project } = useSelector((state: RootState) => state.fileFolderUpload);
  const { pageTitle } = useMemo(() => {
    const pathname = location.pathname;
    let title = PAGE_TITLE[0].title;
    PAGE_TITLE.forEach((page) => {
      if (pathname.includes(page.route)) {
        title = page.title;
      }
    });
    return { pageTitle: title};
  }, [location.pathname]);
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className="sidebar"
      width={170}
      trigger={
        <div className="flex gp-10 aic">
          <CollapseIcon className={collapsed ? "rotate-180" : ""} />
        </div>
      }
    >
      <div className="logo">
        {/* <Link to={PAGE_URL.DASHBOARD}> */}
        <img src={collapsed ? autonomizeLogo : autonomizeFullLogo} alt="autonomize-logo" />
        {/* </Link> */}
      </div>
      <div className="sidebar-nav">
        <ul>
          {getAppMenuItems(roleCode, project?.uuid?.toLowerCase(), project?.type).map((item, i) => {
            return (
              <li key={`left-menu-item-${i}`}>
                <div
                  className={`sidebar-nav-link flex aic gp ${
                    item.link.startsWith(location.pathname) ? "active" : ""
                  }`}
                  title={collapsed ? item.title : ""}
                >
                  {item.icon}
                  {!collapsed && <span className="menu-title">{item.title}</span>}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Sider>
  );
};
