import React from "react";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { InformationIcon } from "../../../../assets/icons";

interface Props {
  param: any;
  isEdit: boolean;
  editConfiguration: (obj: Setting) => void;
}

export const GenerativeAIConfigurationSection = ({ editConfiguration, isEdit, param }: Props) => {
  const value = JSON.parse(param.value).value;
  const setValue = (inputValue: string) => {
    editConfiguration({ ...param, value: JSON.stringify({ value: inputValue }) });
  };
  return (
    //needs to add form
    <>
      <div>
        <div className="flex aic gp-10" title={param.information || ""}>
          <p>{param.name}</p>
          {param.description && <InformationIcon />}
        </div>
        {param.description && (
          <span>
            <i>{`(Range: ${param.description})`}</i>
          </span>
        )}
      </div>
      <Input
        disabled={!isEdit}
        className="tc"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
};

export const GeneraticeAIConfigurationSystemMessageSection = ({
  editConfiguration,
  isEdit,
  param,
}: Props) => {
  const value = JSON.parse(param.value).value;
  const setValue = (inputValue: string) => {
    editConfiguration({ ...param, value: JSON.stringify({ value: inputValue }) });
  };

  return (
    <>
      <div className="flex aic gp-10 m-b" title={param?.information || ""}>
        <h4>{param.name}</h4>
        {param.information && <InformationIcon />}
      </div>
      <TextArea
        disabled={!isEdit}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="System message"
        rows={3}
      />
    </>
  );
};
