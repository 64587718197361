import { Button, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";

export const DatasetSelectDataSourceUrl = () => {
  const [url, setUrl] = useState("");
  const [urlMetadata, setUrlMetadata] = useState("");
  const [sources, setSources] = useState("");

  const handleOnClick = () => {
  };

  return (
    <div className="tabs-content">
      <Form
      className="dataset-from"
      layout="vertical"
      >
      <Form.Item
        label="Add URL"
        name="addUrl"
      >
        <Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder="URL" />
      </Form.Item>
      <Form.Item
        label="Add metadata to url"
      >
        <TextArea rows={5} value={urlMetadata} onChange={(e) => setUrlMetadata(e.target.value)} placeholder="keywords, version numbers, topics" />
      </Form.Item>
      <Form.Item
        label="List of sources"
      >
        <TextArea rows={5} value={sources} onChange={(e) => setSources(e.target.value)} placeholder="Enter" />
      </Form.Item>
      <div className="flex jcsb aic gp">
        <p className="dataset-from-note">
          Note: If uploading multiple files and want to add metadata, please create a folder first.
        </p>
        <Button className="fill" onClick={handleOnClick}>
          Add URL
        </Button>
      </div>
      </Form>
    </div>
  );
};
