import { connect } from "react-redux";
import { Dispatch } from "redux";
import { closeModal, resetUserApproval, RootState, setIsUserApproved } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { ApproveConfirmation } from "./ApproveConfirmation";

type StateProps = {
  visibility: boolean;
  user: IUser;
};

type DispatchProps = {
  handleClose: () => void;
  setIsUserApproved: () => void;
};

export type ApproveConfirmationProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.APPROVE_CONFIRMATION],
  user: state.userApproval.user,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(resetUserApproval());
    dispatch(closeModal(MODAL_TYPE.APPROVE_CONFIRMATION));
  },
  setIsUserApproved: () => {
    dispatch(setIsUserApproved(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveConfirmation);
