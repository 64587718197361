import React, { createContext, useContext } from "react";

interface IAuthorizationContext {
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthorizationContext = createContext<IAuthorizationContext>({
  isAuthorized: true,
  setIsAuthorized: () => {},
});

export const useAuthorization = () => {
  return useContext(AuthorizationContext);
};
