import { Dispatch } from "redux";
import { IDirectoryPath, RootState, closeModal, updateCanFetchFiles } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import { AddEditFolder } from "./AddEditFolder";

type StateProps = {
  topicId: number;
  visibility: boolean;
  directoryPath: IDirectoryPath;
  targetFileFolder: string;
  isEdit: boolean;
  folderPath?:string
};

type DispatchProps = {
  handleClose: () => void;
  handleUpdateCanFetchFiles: (value: boolean) => void;
};

export type CreateFolderProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.ADD_EDIT_FOLDER],
  topicId: state.fileFolderUpload.topicId,
  directoryPath: state.fileFolderUpload.directoryPath,
  targetFileFolder: state.fileFolderUpload.targetFileFolder,
  isEdit: state.fileFolderUpload.isEdit,
  folderPath: state.fileFolderUpload.folderPath
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.ADD_EDIT_FOLDER));
  },
  handleUpdateCanFetchFiles: (value: boolean) => {
    dispatch(updateCanFetchFiles(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFolder);
