import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "antd/lib/layout/layout";
import { logoutUser, RootState } from "../../../store";
import { BGColor, PAGE_TITLE, PAGE_URL, PROJECT_TYPE } from "../../../utils";
import { LogoutIcon, MenuIcon } from "../../../assets/icons";
import { useAuthUser } from "../../../hooks";
import "./app-header.scss";
import { BackArrow } from "../../../components";
import { ActiveDirectoryService } from "../../../api";

export const AppHeader = ({ pageHeader }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { firstName, lastName, roleName } = useAuthUser();
  const { project, document } = useSelector((state: RootState) => state.fileFolderUpload);
  const { pageTitle, displayBackButton, pageRoute } = useMemo(() => {
    const pathname = location.pathname;
    let title = PAGE_TITLE[0].title;
    let backButton = false;
    let pageURL = "";
    PAGE_TITLE.forEach((page) => {
      if (pathname.includes(page.route)) {
        title = page.title;
        backButton = page.displayBackButton;
        pageURL = page.route;
      }
    });
    if (pathname.includes(PAGE_URL.ASK_AUTO) && project.type === PROJECT_TYPE.SINGLE_DOC_QA) {
      backButton = true;
    }
    return { pageTitle: title, displayBackButton: backButton, pageRoute: pageURL };
  }, [location.pathname, pageHeader?.pageTitle]);

  const logout = async (e: any) => {
    e.preventDefault();
    const { data } = await ActiveDirectoryService.activeDirectoryLogout(window.location.origin);
    if (data) {
      dispatch(logoutUser());
      navigate(data.data.logoutUri);
    }
  };

  const prefix = useCallback(() => {
    let refactorTitle = `${pageTitle}`;
    switch (true) {
      case location.pathname.includes(PAGE_URL.DOCUMENTS):
        refactorTitle = `Documents`;
        break;

      case location.pathname.includes(PAGE_URL.ASK_AUTO) && PROJECT_TYPE.SINGLE_DOC_QA === project.type:
        refactorTitle = `Ask Auto`;
        break;

      case location.pathname.includes(PAGE_URL.ASK_AUTO) && PROJECT_TYPE.MULTI_DOC_QA === project.type:
        refactorTitle = `Ask Auto`;
        break;

      case location.pathname.includes(PAGE_URL.DATASETS):
        refactorTitle = `Datasets`;
        break;

      case location.pathname.includes(PAGE_URL.SETTINGS):
        refactorTitle = `Settings`;
        break;
    }
    return refactorTitle;
  }, [project?.displayName, document?.name, location.pathname, pageTitle])


const getName = (): string => {
  if (typeof firstName !== 'string' || firstName.trim() === '') {
    return '';
  }

  const names = firstName.trim().split(" ");

  if (names.length > 1) {
    return `${names[0][0] || ''}${names[1][0] || ''}`.toUpperCase();
  } else {
    const firstInitial = firstName[0] || '';
    const lastInitial = typeof lastName === 'string' ? (lastName[0] || '') : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  }
}

  const getColorForInitials = (initials: string) => {
    const charCodeSum = initials.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    return BGColor[charCodeSum % BGColor.length];
  };

  const initials = getName();
  const backgroundColor = getColorForInitials(initials);
  return (
    <Header className="flex aic jcsb header">
      <div className="flex gp aic">
        {displayBackButton && <BackArrow />}
        <h1 className="header-h1 m-0">{prefix()}</h1>
      </div>
      <div className="header-right flex gp-10 aic">
        <div className="header-content-menu pr cursor-pointer">
          <a className="header-dropdown name-initialise" href="#" style={{ backgroundColor }}>
            {getName()}
          </a>
          <div className="header-dropdown-menu flex flex-column">
            <div className="header-content-profile-content">
              <p>
                {firstName} {lastName} <span>{roleName}</span>
              </p>
            </div>
            {/* <a
              href="#"
              className="header-dropdown-menu-item flex aic no-wrap"
              onClick={(e) => {
                e.preventDefault();
                dispatch(openModal(MODAL_TYPE.EDIT_PROFILE));
              }}
            >
              <EditProfileIcon />
              Edit Profile
            </a> */}
            <a href="#" className="header-dropdown-menu-item flex aic no-wrap" onClick={logout}>
              <LogoutIcon />
              Logout
            </a>
          </div>
        </div>
      </div>
    </Header>
  );
};
interface Props {
  pageHeader?: PageHeader;
}

export interface PageHeader {
  pageTitle: string | undefined;
  displayBackButton: boolean;
  pageRoute: string;
}

export interface ILoadingPrivateLayout {
  isPage: boolean;
}