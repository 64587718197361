import { useCallback, useState } from "react";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";
import { FileFolder, uploadFileFolderService } from "../api";
import { useDispatch } from "react-redux";
import { updateCanFetchFiles } from "../store";

interface Props {
  setIsViewFilesFoldersAuthorized: (value: React.SetStateAction<boolean>) => void;
  setFilesList: (value: React.SetStateAction<FileFolder[]>) => void;
  onPaginationChange: (updatedPage: number, updatedPageSize: number) => void;
  pageSize: number;
}

export function useFetchFileStatus({
  setIsViewFilesFoldersAuthorized,
  setFilesList,
  onPaginationChange,
  pageSize,
}: Props) {
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [statuses, setStatuses] = useState([] as IDropdownOption[]);
  const dispatch = useDispatch();

  const [selectedStatus, setSelectedStatus] = useState({
    id: -1,
    value: "View All",
  } as IDropdownOption);

  const handleStatusChange = (e: string, option: IDropdownOption) => {
    setSelectedStatus(option);
    setFilesList([]);
    onPaginationChange(1, pageSize);
    dispatch(updateCanFetchFiles(true));
  };

  const fetchStatus = useCallback(async () => {
    try {
      setIsStatusLoading(true);
      const { data } = await uploadFileFolderService.getFileStatus();
      setStatuses(() => {
        const statusSet = new Set();
        
        const uniqueStatuses = data.data.filter(status => {
          const isDuplicate = statusSet.has(status.name);
          statusSet.add(status.name);
          return !isDuplicate;
        }).map(status => ({ id: status.id, value: status.name, key: status.id }));
      
        return [
          {
            id: -1,
            value: "View All",
          },
          ...uniqueStatuses,
        ];
      });
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsViewFilesFoldersAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setIsStatusLoading(false);
    }
  }, [setIsViewFilesFoldersAuthorized]);

  return {
    isStatusLoading,
    statuses,
    selectedStatus,
    fetchStatus,
    handleStatusChange,
  };
}
