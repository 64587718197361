import { useCallback, useState } from "react";
import { PROJECT_TYPE, STATUS_CODE, openNotificationWithIcon } from "../utils";
import { TopicsService } from "../api/topic";
import { useAuthorization } from "./useAuthorization";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateStorage, updateTopicId } from "../store";
import { ProjectsService } from "../api";

export const useProject = () => {
  const [loading, setLoading] = useState(false);
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);
  const [topics, setTopics] = useState([] as ITopic[]);
  const [projects, setProjects] = useState([] as IProjects[]);
  const { setIsAuthorized } = useAuthorization();
  const dispatch = useDispatch();
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  const fetchProjects = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await ProjectsService.getProjects();
      setProjects(data.data);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setLoading(false);
    }
  }, [setIsAuthorized]);

  const fetchTopics = useCallback(async (projectId?: string) => {
    setLoading(true);
    try {
      const { data } = await TopicsService.getTopics(projectId || project.uuid);
      const refactorDataOrder = data.data.sort((a, b) => a.id - b.id);
      setTopics(refactorDataOrder);
      // project.type !== PROJECT_TYPE.MULTI_DOC_QA &&
        dispatch(updateTopicId(refactorDataOrder[0]?.id || -1));
      setTopics(refactorDataOrder);
      dispatch(updateStorage(refactorDataOrder[0]?.storage));
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    } finally {
      setLoading(false);
    }
  }, [setIsAuthorized, project]);

  const deleteTopic = useCallback(async (id: number) => {
    setDeleteProjectLoading(true);
    try {
      await TopicsService.deleteTopic(id);
      openNotificationWithIcon("", "Topic deleted successfully", "success");
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setDeleteProjectLoading(false);
    }
  }, []);

  return {
    fetchTopics,
    deleteTopic,
    loading,
    deleteProjectLoading,
    topics,
    fetchProjects,
    projects
  };
};
