import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IModal {
  [name: string]: boolean;
}

interface UiSlice {
  modal: IModal;
  isDeleteConfirmed: boolean;
  dataSource: string;
  currPage: number;
}
export const uiInitialState: UiSlice = {
  modal: {} as IModal,
  isDeleteConfirmed: false,
  dataSource: "",
  currPage: 1
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: uiInitialState,
  reducers: {
    openModal: (state: UiSlice, { payload }: PayloadAction<{modalName: string, dataSource: string}>) => {
      state.modal[payload.modalName] = true;
      state.dataSource = payload.dataSource;
    },
    closeModal: (state: UiSlice, { payload }: PayloadAction<string>) => {
      state.modal[payload] = false;
    },
    updateDeleteConfirmation: (state: UiSlice, { payload }: PayloadAction<boolean>) => {
      state.isDeleteConfirmed = payload;
    },
    updatePage: (state: UiSlice, { payload }: PayloadAction<number>) => {
      state.currPage = payload;
    },
  },
});

export const { openModal, closeModal, updateDeleteConfirmation, updatePage } = uiSlice.actions;

export default uiSlice.reducer;
