import React from "react";
import { SourceConfirmationProps } from ".";
import { Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { TopicsService } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateProject, updateStorage } from "../../../store";
import { openNotificationWithIcon } from "../../../utils";
import { useProject } from "../../../hooks";

const SourceConfirmation = ({
  visibility,
  handleClose,
}: SourceConfirmationProps) => {
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  const dispatch = useDispatch();
  const { fetchTopics } = useProject();
  const handleConfirm = async () => {
    try {
      const { data } = await TopicsService.createTopics(
        project.name,
        "azure",
        project.uuid,
        true,
        project.name,
      );
      dispatch(
        updateProject({
          name: "",
          uuid: project.uuid,
          type: project.type,
          displayName: project.name,
          storage: "azure",
          sourceName: project.name,
        })
      );
      openNotificationWithIcon(
        "",
        `topic ${data.data.displayName} successfully. Fetching file from the data source`,
        "success"
      );
      handleClose();
      fetchTopics();
      dispatch(updateStorage(""));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      dispatch(updateStorage(""));
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="Source Confirmation Modal"
      open={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>{"Source Confirmation"}</h1>
      </div>
      <div className="deleteConfirmation tc addClient-content">
        {/* <h1>Are you sure?</h1> */}
        <p>
          You cannot change the source after confirmation.
          <br /> Are you sure?
        </p>
        <div className="flex jcc gp">
          <Button className="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="fill" onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SourceConfirmation;
