import { IQuestAns } from "../../interfaces/chat";

export const downloadFile = ({
  data,
  fileName,
  fileType,
}: {
  data: string;
  fileName: string;
  fileType: string;
}) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const exportToCsv = (chatData: IQuestAns[], delimeter: string) => {
  const columns = `Ques${delimeter}Ans`;
  const headers = [columns];
  const chatDataCsv = chatData.map((resData) => {
    const escapedQuestion = resData.question.replace(/"/g, '""');
    const escapedAnswer = typeof resData.answer === 'string'?resData.answer.replace(/"/g, '""'):"";
    return `"${escapedQuestion}"${delimeter}"${escapedAnswer}"`;
  });
  downloadFile({
    data: [...headers, ...chatDataCsv].join("\n"),
    fileName: "result.csv",
    fileType: "text/csv",
  });
};
