import { Dispatch } from "redux";
import { RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import { EditFile } from "./EditFile";

type StateProps = {
  visibility: boolean;
  targetFilePath: string;
  topicId: number;
};

type DispatchProps = {
  handleClose: () => void;
};

export type EditFileProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.EDIT_FILE],
  targetFilePath: `${state.fileFolderUpload.directoryPath}${state.fileFolderUpload.targetFileFolder}`,
  topicId: state.fileFolderUpload.topicId
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.EDIT_FILE));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFile);
