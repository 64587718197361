export const COLORS = [
  "#006400",
  "#8b4513",
  "#808000",
  "#483d8b",
  "#008080",
  "#000080",
  "#9acd32",
  "#9932cc",
  "#ff4500",
  "#ffa500",
  "#00bfff",
  "#ffff00",
  "#ff00ff",
  "#db7093",
  "#ffa07a",
  "#7fffd4",
  "#ff1493",
  "#c0c0c0",
];

export const BGColor = [
  '#1A237E', // Dark Indigo
  '#1B5E20', // Dark Green
  '#0D47A1', // Dark Blue
  '#311B92', // Dark Purple
  '#880E4F', // Dark Pink
  '#3E2723', // Dark Brown
  '#263238', // Dark Blue Grey
  '#004D40', // Dark Teal
  '#BF360C', // Dark Orange
  '#4A148C', // Dark Violet
  '#006064', // Dark Cyan
  '#1A237E', // Dark Navy
  '#33691E', // Dark Olive
  '#4E342E', // Dark Sienna
  '#212121', // Almost Black
  '#37474F'  // Dark Slate
]