import React from "react";
import { DrawerIcon } from "../../assets/icons";
import "./expand-toggle-icon.scss";

interface ExpandToggleIconProps {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  RTL: boolean;
  bgColor?: string;
  position?: { left: string };
}

const ExpandToggleIcon: React.FC<ExpandToggleIconProps> = ({
  expanded,
  setExpanded,
  RTL,
  bgColor = "#F7F7F9",
  position = { left: "-25px"}
}) => {
  return (
    <div
      className={`expand-toggle-icon ${RTL ? "" : "LTR"}`}
      style={{ backgroundColor: bgColor, ...position }}
      onClick={() => setExpanded(!expanded)}
    >
      <DrawerIcon className={`${expanded && RTL ? "" : "rotate-180"}`} />
    </div>
  );
};

export default ExpandToggleIcon;
