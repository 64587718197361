import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import "./back-button.scss";

export const BackArrow = () => {
  const navigate = useNavigate();
  return <LeftOutlined onClick={() => navigate(-1)} />;
};

interface Props {
  className?: string;
}

export const BackButton = ({ className }: Props) => {
  const navigate = useNavigate();
  return (
    <div className={`back-icon ${className}`} onClick={() => {
      navigate('/dasboard')
    }}>
      <Link to={"/dashboard"}>
        <LeftOutlined onClick={() => {
        }} />
        Back
      </Link>
    </div>
  );
};
