import { Button, UploadFile } from "antd";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IDirectoryPath,
  RootState,
  openModal,
  updateCanFetchFiles,
  updateDirectoryPath,
  // updateContinuationToken,
  updateFolderIsEditCheck,
} from "../../../../store";
import { openNotificationWithIcon } from "../../../../utils";
import { FileFolder, uploadFileFolderService } from "../../../../api";
import { FileUploader } from "./FileUploader";
import { TopicsDropdown } from "../../../../components";
import { FolderFilled } from "@ant-design/icons";

const acceptedFormats = [".pdf", ".txt", ".docx"];

interface Props {
  directoryPath: IDirectoryPath;
  selectedProject: IDropdownOption;
  setFilesList: React.Dispatch<React.SetStateAction<FileFolder[]>>;
  onPaginationChange: (updatedPage: number, updatedPageSize: number) => void;
  page: number;
  pageSize: number;
  storage: string;
}

export const FileFolderUpload = (props: Props) => {
  const {
    directoryPath,
    selectedProject,
    setFilesList,
    onPaginationChange,
    pageSize,
    storage,
  } = props;
  const dispatch = useDispatch();
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  const { topicId } = useSelector((state: RootState) => state.fileFolderUpload);
  const { folderPath }=useSelector((state:RootState) => state.fileFolderUpload);

  const onFileUpload = useCallback(
    async (file: UploadFile<any>) => {
      try {
        const generateFilePath = () => {
          let tempFolderPath = directoryPath[directoryPath.length - 1]?.path || ''
          if(tempFolderPath && !tempFolderPath.endsWith('/')) tempFolderPath += '/'
          return `${tempFolderPath}${file.name}`
        }
        const filePath =(folderPath ? (folderPath.endsWith('/')?`${folderPath}${file.name}` : `${folderPath}/${file.name}`)
        : generateFilePath());

        const { data } = await uploadFileFolderService.getUploadSignedUrl(
          topicId,
          filePath,
          storage
        );
        storage === "sharepoint"
          ? await uploadFileFolderService.uploadFilesSharePoint(data.data, file)
          : await uploadFileFolderService.uploadFiles(data.data.signedUrl, file);
        openNotificationWithIcon("", `${file.name} uploaded successfully!`, "success");
        await uploadFileFolderService.startFileProcessing(topicId, filePath, project.uuid);
        setFilesList([]);
        onPaginationChange(1, pageSize);
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    },
    [directoryPath, onPaginationChange, pageSize, topicId, setFilesList, storage]
  );

  const onFilesUploadComplete = useCallback(async () => {
    dispatch(updateCanFetchFiles(true));
  }, [dispatch]);

  return (
    <>
      <FileUploader
        onSubmit={onFileUpload}
        onFilesUploadComplete={onFilesUploadComplete}
        formats={acceptedFormats}
        storage={storage}
      />
    </>
  );
};
