export const USERS_DROPDOWN_OPTIONS: IDropdownOption[] = [
  {
    id: 1,
    value: "To be Approved",
    key: 1
  },
  {
    id: 2,
    value: "Approved",
    key: 2
  },
];
