import { Dispatch } from "redux";
import { RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import ConnectToDataSource from "./ConnectToDataSource";

type StateProps = {
  visibility: boolean;
  dataSource: string;
};

type DispatchProps = {
  handleClose: () => void;
};

export type ConnectToDataSourceProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.CONNECT_TO_DATA_SOURCE],
  dataSource: state.ui.dataSource
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.CONNECT_TO_DATA_SOURCE));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectToDataSource);
