import React, { useEffect, useState } from "react";
import { openNotificationWithIcon } from "../utils";
import "./pdf-viewer/pdf-content.scss";

interface TextViewerProps {
  fileUrl: string;
}

export const TextViewer = ({ fileUrl }: TextViewerProps) => {
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    const fetchTextFile = async () => {
      try {
        const response = await fetch(fileUrl);
        if (response.ok) {
          const text = await response.text();
          setFileContent(text);
        } else {
          openNotificationWithIcon("", "Failed to fetch the text file.", "error");
        }
      } catch (error) {
        openNotificationWithIcon("", "Error fetching the text file:", "error");
      }
    };

    fetchTextFile();
  }, [fileUrl]);

  return (
    <div className="e-pdf e-content">
      <pre>{fileContent}</pre>
    </div>
  );
};
