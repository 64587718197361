import React from "react";
import { MODAL_TYPE } from "../../../utils";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { openModal } from "../../../store";
import { ProjectsTable } from "./ProjectsTable";
import { withAuthorization } from "../../../components";


const AuthorizedProjectsTable = withAuthorization(ProjectsTable);

export const ProjectsList = () => {
  const dispatch = useDispatch();

  const handleCreateContainerClick = () => {
    dispatch(
      openModal({
        modalName: MODAL_TYPE.ADD_PROJECT,
        dataSource: "",
      })
    );
  };
  
  
  return (
    <div>
      <div className="flex aic gp jcsb">
        <h4 className="font-16">List of Topics</h4>
        <Button className="fill" onClick={handleCreateContainerClick}>
          Add Topic
        </Button>
      </div>
      <AuthorizedProjectsTable />
    </div>
  );
};
