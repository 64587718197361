import { Dispatch } from "redux";
import { connect } from "react-redux";
import { closeModal, RootState} from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import SourceConfirmation from "./SourceConfirmation";

type StateProps = {
  visibility: boolean;
};
type DispatchProps = {
  handleClose: () => void;
};
export type SourceConfirmationProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.SOURCE_CONFIRMATION],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.SOURCE_CONFIRMATION));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SourceConfirmation);
