import React, { useEffect, useMemo, useState } from "react";
import { CreateFolderProps } from ".";
import { Button, Input, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { uploadFileFolderService } from "../../../api";
import { openNotificationWithIcon } from "../../../utils";
import TextArea from "antd/lib/input/TextArea";

export const AddEditFolder = (props: CreateFolderProps) => {
  const {
    handleClose,
    visibility,
    handleUpdateCanFetchFiles,
    directoryPath,
    targetFileFolder,
    isEdit,
    topicId,
    folderPath
  } = props;

  const [folderName, setFolderName] = useState("");
  const [folderFilePath, setFolderFilePath] = useState("");
  const [folderMetadata, setFolderMetaData] = useState("");
  const [originalFolderMetadata, setOriginalMetadata] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitButtonDisabled = useMemo(() => {
    if (isEdit) {
      return folderMetadata === originalFolderMetadata;
    } else {
      return folderName === "";
    }
  }, [folderMetadata, folderName, isEdit, originalFolderMetadata]);

  useEffect(() => {
    let name = "";
    if (isEdit) {
      name = targetFileFolder.split("/")[0];
    }
    setFolderName(name);
    setFolderFilePath(`${directoryPath}${targetFileFolder}`);
  }, [directoryPath, isEdit, targetFileFolder]);

  useEffect(() => {
    const getMetadata = async () => {
      setIsLoading(true);
      try {
        const { data } = await uploadFileFolderService.getFileFolderMetadata(topicId, folderFilePath);
        setFolderMetaData(data.data.content);
        setOriginalMetadata(data.data.content);
      } catch (error: any) {
        openNotificationWithIcon("", error.response.data.message, "error");
        handleClose();
      } finally {
        setIsLoading(false);
      }
    };
    if (isEdit && folderFilePath) {
      getMetadata();
    }
  }, [folderFilePath, handleClose, isEdit, topicId]);

  const handleCreateFolderButtonClick = async () => {
    // const metadataFile = createTxtFile("metadata", folderMetadata);
    setIsLoading(true);
    try {
      const generateFilePath = () => {
        let tempFolderPath = directoryPath[directoryPath.length - 1]?.path || ''
        if(tempFolderPath && !tempFolderPath.endsWith('/')) tempFolderPath += '/'
        return `${tempFolderPath}${folderName}/` // TODO : ONLY FOR BLOB FOR NOW (NO ACCESS TO STORAGE TYPE NOW)
      }
      const filePath = folderPath ? (folderPath.endsWith('/')?`${folderPath}${folderName}/` : `${folderPath}/${folderName}/`)
        : generateFilePath();
      await uploadFileFolderService.createFolder(
        topicId,
        filePath,
        folderMetadata
      );
      openNotificationWithIcon("Folder created successfully", "", "success");
      handleUpdateCanFetchFiles(true);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditFolderButtonClikc = async () => {
    setIsLoading(true);
    try {
      await uploadFileFolderService.updateFileFolderMetadata(topicId, folderFilePath, folderMetadata);
      openNotificationWithIcon("Folder Metadata updated successfully", "", "success");
      handleUpdateCanFetchFiles(true);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitButtonClick = async () => {
    if (isEdit) {
      await handleEditFolderButtonClikc();
    } else {
      await handleCreateFolderButtonClick();
    }
    handleClose();
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1>{isEdit ? "Edit Folder" : "Create Folder"}</h1>
      </div>
      <div className="addClient-content">
        <p className="font-Semibold m-b">Folder Name : </p>
        <Input
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          disabled={isEdit}
        />
        <p className="font-Semibold m-b m-t">{`Add Metadata (optional) : `}</p>
        <TextArea
          rows={5}
          placeholder="keywords, version numbers, topics"
          value={folderMetadata}
          onChange={(e) => setFolderMetaData(e.target.value)}
          disabled={isLoading}
        />
        <div className="tr m-b m-t">
          <Button
            className="fill"
            disabled={submitButtonDisabled && !isLoading}
            onClick={handleSubmitButtonClick}
            loading={isLoading}
          >
            {isEdit ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
