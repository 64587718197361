import React, { useEffect, useState } from "react";
import { ModelSettings } from "./generative-ai-container";
import { Tabs } from "antd";

const { TabPane } = Tabs;

interface Props {
  querySettings: ModelData[];
  projectId: string;
}

const GenerativeAITabs = ({ selectedTab, onChange, tabs }: GenerativeAITabsProps) => {
  return (
    <div className="tabs m-b">
      <Tabs tabPosition="left" onChange={onChange} activeKey={selectedTab}>
        {tabs.map((key) => (
          <TabPane tab={key.name} key={key.name} disabled={!key.isActive} />
        ))}
      </Tabs>
    </div>
  );
};

export const GenerativeAI = (props: Props) => {
  const { querySettings, projectId } = props;
  const [extendedQuerySettings, setExtendedQuerySettings] = useState<ModelData[]>([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [component, setComponent] = useState(<></>);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    const extendedSettings = [
      ...querySettings,
      {
        modelId: -1,
        name: "Google Med-Palm2",
        setting: [],
        projectId,
        isActive: false,
        isDefault: false,
      },
      {
        modelId: -2,
        name: "Med-Alpaca",
        setting: [],
        projectId,
        isActive: false,
        isDefault: false,
      },
    ];
    setExtendedQuerySettings(extendedSettings);

    const defaultTab =
      extendedSettings.find((item) => item.isDefault)?.name || extendedSettings[0]?.name || "";
    setSelectedTab(defaultTab);
  }, [querySettings]);

  useEffect(() => {
    const res = (
      <ModelSettings
        querySettings={extendedQuerySettings}
        projectId={projectId}
        selectedTab={selectedTab}
      />
    );
    setComponent(res);
  }, [selectedTab, extendedQuerySettings]);


  return (
    <div className="grid setting-generative">
      <GenerativeAITabs
        {...{ selectedTab, onChange, tabs: extendedQuerySettings }}
      />
      {component}
    </div>
  );
};

interface GenerativeAITabsProps {
  selectedTab: string;
  onChange: (key: string) => void;
  tabs: ModelData[];
}

export default GenerativeAI;