import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { PaginationLeftArrowIcon, PaginationRightArrowIcon } from "../../assets/icons";
import { PROJECT_SETTINGS_HEADINGS } from ".";
import {
  DocumentIngestionSettings,
  GenerativeAI,
  RetrievalParameters,
  QandAConfiguration,
} from "./project-settings-container";
import { useOutletContext, useParams } from "react-router-dom";
import { useProjectSettings } from "../../hooks";
import { Loading, withAuthorization } from "../../components";
import { ProjectsService, TopicsService } from "../../api";


const AuhtorizedDocumentIngestionSettings = withAuthorization(DocumentIngestionSettings);

export const ProjectSettingsContainer = () => {
  const { projectId: uuid } = useParams();
  const {
    fetchSettings,
    loading,
    topicsSettings,
    fetchDocumentIngestionSettings,
    documentIngestionSettings,
    retrievalParametersSettings,
    fetchRetrievalParametersSettings,
    fetchGenerativeAiParametersSettings,
    generativeAiParametersSettings,
    fetchQAConfiguration,
    qaConfiguration
  } = useProjectSettings();
  const [fields, setFields] = useState<Field[]>([{ fieldName: "", fieldPrompt: "" }]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProjectSettings = async () => {
      await Promise.all([fetchDocumentIngestionSettings(uuid),
        fetchRetrievalParametersSettings(uuid),
        fetchGenerativeAiParametersSettings(uuid),
        fetchQAConfiguration(uuid)])
    };
    getProjectSettings();
  }, [fetchSettings, uuid]);

  useEffect(() => {
    const handleProjectsGroupsChange = async () => {
      try {
        const { data } = await ProjectsService.getProjectsFields(uuid);
        const parsedFields = JSON.parse(data.data[0].fields);
        if (parsedFields && parsedFields.length > 0) {
          setFields(parsedFields);
        }
      } catch (error) {
        //TODO uncomment after demo 
        // openNotificationWithIcon("Error", "Failed to fetch project fields", "error");
      }
    };
    handleProjectsGroupsChange();
  }, []);

  return (
    <>
      {!documentIngestionSettings || loading ? (
        <Loading />
      ) : (
        <div className="query-settings">
          <Collapse
            accordion
            defaultActiveKey={[PROJECT_SETTINGS_HEADINGS.DOCUMENT_INGESTION_SETTINGS]}
            expandIconPosition="end"
            expandIcon={(e) =>
              e.isActive ? <PaginationLeftArrowIcon /> : <PaginationRightArrowIcon />
            }
          >
            <Collapse.Panel
              header={PROJECT_SETTINGS_HEADINGS.DOCUMENT_INGESTION_SETTINGS}
              key={PROJECT_SETTINGS_HEADINGS.DOCUMENT_INGESTION_SETTINGS}
            >
              {uuid && (
                <AuhtorizedDocumentIngestionSettings
                  ingestionSetting={documentIngestionSettings}
                  projectId={uuid}
                  // hasDocument={businessGroupSettings.hasDocument}
                />
              )}
            </Collapse.Panel>
            <Collapse.Panel
              header={PROJECT_SETTINGS_HEADINGS.RETRIEVAL_PARAMETERS}
              key={PROJECT_SETTINGS_HEADINGS.RETRIEVAL_PARAMETERS}
            >
              {uuid && (
                <RetrievalParameters querySettings={retrievalParametersSettings} projectId={uuid} />
              )}
            </Collapse.Panel>
            <Collapse.Panel
              header={PROJECT_SETTINGS_HEADINGS.GENERATIVE_AI_PARAMETERS}
              key={PROJECT_SETTINGS_HEADINGS.GENERATIVE_AI_PARAMETERS}
            >
              {uuid && (
                <GenerativeAI querySettings={generativeAiParametersSettings} projectId={uuid} />
              )}
            </Collapse.Panel>
            <Collapse.Panel
              header={PROJECT_SETTINGS_HEADINGS.Q_A_CONFIGURATION}
              key={PROJECT_SETTINGS_HEADINGS.Q_A_CONFIGURATION}
            >
              <QandAConfiguration
                qaConfiguration={qaConfiguration}
                isLoading={isLoading}
              />
            </Collapse.Panel>
          </Collapse>
        </div>
      )}
    </>
  );
};
