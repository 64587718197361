import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FileFolder } from "../../api";

export type IDirectoryPath = { name: string; path: string }[];
export interface IProject {
  uuid: string;
  displayName: string;
  name?: string;
  embeddingModel: {
    value: string;
  };
  description?: string;
  createdAt: string;
  updatedAt: string;
  projectType: string;
}
export interface IFileFolderUploadInitialState {
  topicId: number;
  directoryPath: IDirectoryPath;
  canFetchFiles: boolean;
  canFetchContainers: boolean;
  targetFileFolder: string;
  isEdit: boolean;
  continuationToken: string;
  storage: any;
  isFolder: boolean;
  projectType: string;
  project: { name: string; uuid: string, type: string, displayName: string , sourceName?:string , storage:string};
  documentUUID: string;
  projects: IProject[];
  selectedProjects: IProject;
  document: FileFolder | null;
  folderPath?:string;
}

export const fileFolderUploadInitialState: IFileFolderUploadInitialState = {
  topicId: -1,
  directoryPath: [],
  canFetchFiles: false,
  canFetchContainers: true,
  targetFileFolder: "",
  isEdit: false,
  continuationToken: "",
  storage: "",
  isFolder: false,
  projectType: "default",
  project: { name: "", uuid: "", type: "", displayName: "", storage:"", sourceName:""},
  documentUUID: "",
  projects: [],
  selectedProjects: {
    uuid: "",
    displayName: "",
    embeddingModel: {
      value: "",
    },
    description: "",
    createdAt: "",
    updatedAt: "",
    projectType:""
  },
  document: null,
  folderPath:""
};

export const FileFolderUploadSlice = createSlice({
  name: "fileFolderUpload",
  initialState: fileFolderUploadInitialState,
  reducers: {
    updateDirectoryPath: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<IDirectoryPath>
    ) => {
      state.directoryPath = payload;
    },
    updateStorage: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string | undefined>
    ) => {
       state.storage = payload;
    },
    updateCanFetchFiles: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.canFetchFiles = payload;
    },
    updateContinuationToken: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.continuationToken = payload;
    },
    updateCanFetchContainers: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.canFetchContainers = payload;
    },
    updateTargetFileFolder: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.targetFileFolder = payload;
    },
    updateFolderIsEditCheck: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isEdit = payload;
    },
    updateTopicId: (state: IFileFolderUploadInitialState, { payload }: PayloadAction<number>) => {
      state.topicId = payload;
    },
    updateFolderPath: (state: IFileFolderUploadInitialState, { payload }: PayloadAction<string | undefined>) => {
      state.folderPath = payload;
    },
    updateProjectType: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.projectType = payload;
    },
    updateIsFolder: (state: IFileFolderUploadInitialState, { payload }: PayloadAction<boolean>) => {
      state.isFolder = payload;
    },
    updateProject: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<typeof fileFolderUploadInitialState.project>
    ) => {
      state.project = {
        name: payload.name,
        uuid: payload.uuid.toLowerCase(),
        type: payload.type,
        displayName: payload.displayName,
        sourceName: payload.sourceName,
        storage: payload.storage,
      };
    },
    updateDocumentUUID: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.documentUUID = payload;
    },
    updateProjects: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<IProject[]>
    ) => {
      state.projects = payload;
    },
    updateSelectedProject: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<IProject>
    ) => {
      state.selectedProjects = payload;
    },
    updateDocument: (
      state: IFileFolderUploadInitialState,
      { payload }: PayloadAction<FileFolder>
    ) => {
      state.document = payload;
    },
    resetDocument: (
      state: IFileFolderUploadInitialState,
    ) => {
      state.document = null;
    },
  },
});

export const {
  updateCanFetchFiles,
  updateDirectoryPath,
  updateFolderIsEditCheck,
  updateTargetFileFolder,
  updateCanFetchContainers,
  updateTopicId,
  updateContinuationToken,
  updateStorage,
  updateIsFolder,
  updateProjectType,
  updateProject, 
  updateProjects,
  updateDocumentUUID,
  updateSelectedProject,
  resetDocument,
  updateDocument,
  updateFolderPath
} = FileFolderUploadSlice.actions;

export default FileFolderUploadSlice.reducer;
