import Table, { ColumnsType } from "antd/lib/table";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DOCUMENT_STATUS,
  MODAL_TYPE,
  PROJECT_TYPE,
  STORAGE_TYPES,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../../../utils";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  openModal,
  updateCanFetchFiles,
  updateTargetFileFolder,
  RootState,
  updateDeleteConfirmation,
  updateFolderIsEditCheck,
  updateStorage,
  updateDirectoryPath,
  IDirectoryPath,
  updateIsFolder,
  updateFolderPath,
} from "../../../../store";
import { AnyAction } from "redux";
import { FileFolder, uploadFileFolderService } from "../../../../api";
import {
  FileStatusDropdown,
  Loading,
  NotAccessibleFallback,
  TopicsDropdown,
} from "../../../../components";
import { Button, Empty } from "antd";
import {
  FolderFilled,
  FolderOpenOutlined,
  FolderOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

const getClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.PROCESSING:
      return "#ff9800";
    case DOCUMENT_STATUS.READY:
      return "#44c658";
    case DOCUMENT_STATUS.FAILED:
      return "#f44336";
  }
};

interface IUploadedDocumentsDataType {
  key: React.Key;
  fileFolder: string;
  createdOn: string;
  updatedOn: string;
  status: string;
  action: string;
  isFolder: boolean;
  storage: string;
  path: string;
}

const getColumns = (
  onFileClick: React.Dispatch<SetStateAction<IDirectoryPath>>,
  dispatch: React.Dispatch<AnyAction>,
  setFilesList: React.Dispatch<React.SetStateAction<FileFolder[]>>,
  onPaginationChange: (updatedPage: number, updatedPageSize: number) => void,
  pageSize: number
): ColumnsType<IUploadedDocumentsDataType> => [
  {
    title: "Folders & Files",
    key: "fileFolder",
    dataIndex: "fileFolder",
    render: (item: string, value) => (
      <span
        className="file-folder-name"
        onClick={() => {
          if (item.endsWith("/") || value.isFolder) {
            onFileClick((prev) => [
              ...prev,
              { name: value.fileFolder.replaceAll("/", ""), path: value.path },
            ]);
            dispatch(
              updateDirectoryPath([
                { name: value.fileFolder.replaceAll("/", ""), path: value.path },
              ])
            );
            dispatch(updateFolderPath(value.path))
            onPaginationChange(1, pageSize);
            setFilesList([]);
          } else {
            dispatch(updateStorage(value.storage));
            dispatch(updateTargetFileFolder(value.fileFolder));
            dispatch(updateIsFolder(value.isFolder));
            dispatch(
              openModal({
                modalName: MODAL_TYPE.DATA_SOURCE,
                dataSource: "",
              })
            );
          }
        }}
      >
        {value?.isFolder && (
          <>
            <FolderOpenOutlined className="folder-icon folder-icon-open" />
            <FolderOutlined className="folder-icon folder-icon-closed" />
          </>
        )}{" "}
        <span>{item?.includes("/") ? item.split("/").slice(value.isFolder ? -2 : -1) : item}</span>
      </span>
    ),
    className: "files-table-fileFolder",
  },
  {
    title: "Created On",
    key: "createdOn",
    dataIndex: "createdOn",
    className: "files-table-createdOn",
  },
  {
    title: "Updated On",
    key: "updatedOn",
    dataIndex: "updatedOn",
    className: "files-table-updatedOn",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text, value) => (
      <>
        {value.status && (
          <div>
            <div className="status" style={{ color: getClass(value.status) }}>
              <p style={{ color: getClass(value.status) }}>{value.status}</p>{" "}
            </div>
          </div>
        )}
      </>
    ),
    className: "files-table-status",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (text, value) => (
      <div>
        {["NOT PROCESSED", "DELETED", "PROCESSING"].includes(value.status) ||
          (!value.isFolder && (
            <>
              <a>
                <DeleteIcon
                  onClick={() => {
                    dispatch(updateTargetFileFolder(value.fileFolder));
                    dispatch(updateIsFolder(value.isFolder));
                    dispatch(
                      openModal({
                        modalName: MODAL_TYPE.DELETE_CONFIRMATION,
                        dataSource: "",
                      })
                    );
                  }}
                />
              </a>
            </>
          ))}
      </div>
    ),
    align: "right",
    className: "files-table-action",
  },
];

interface Props {
  list: FileFolder[];
  isLoading: boolean;
  directoryPath: IDirectoryPath;
  topicId: number;
  setDirectoryPath: React.Dispatch<SetStateAction<IDirectoryPath>>;
  isViewFilesFoldersAuthorized: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  continuationToken: string;
  selectedTopic: IDropdownOption;
  setSelectedTopic: Dispatch<SetStateAction<IDropdownOption>>;
  setFilesList: React.Dispatch<React.SetStateAction<FileFolder[]>>;
  isStatusLoading: boolean;
  statuses: IDropdownOption[];
  selectedStatus: IDropdownOption;
  fetchStatus: () => Promise<void>;
  handleStatusChange: (e: string, option: IDropdownOption) => void;
  totalFiles: number;
  onPaginationChange: (updatedPage: number, updatedPageSize: number) => void;
  page: number;
  pageSize: number;
  project: string;
  handleProjectChange: (e: string, option: IDropdownOption, projects: ITopic[]) => void;
}

export const UploadedFilesFolders = (props: Props) => {
  const {
    list,
    isLoading,
    setDirectoryPath,
    directoryPath,
    topicId,
    isViewFilesFoldersAuthorized,
    setIsLoading,
    selectedTopic: selectedTopic,
    setSelectedTopic: setSelectedTopic,
    setFilesList,
    isStatusLoading,
    statuses,
    selectedStatus,
    fetchStatus,
    handleStatusChange,
    totalFiles,
    pageSize,
    page,
    onPaginationChange,
    handleProjectChange,
  } = props;

  const dispatch = useDispatch();
  const { isDeleteConfirmed } = useSelector((state: RootState) => state.ui);
  const { targetFileFolder, isFolder } = useSelector((state: RootState) => state.fileFolderUpload);
  const [isDeleteSuccessFetchApiCall, setIsDeleteSuccessFetchApiCall] = useState(false);
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);

  useEffect(() => {
    const deleteFileFolder = async () => {
      try {
        setIsLoading(true);
        const newPath = directoryPath[directoryPath.length - 1]?.path;
        const refactoredPath = newPath ? (newPath.endsWith("/") ? newPath : `${newPath}/`) : "";
        let path;
        if (project.storage === STORAGE_TYPES.AZURE || (!selectedTopic. folderPath && !refactoredPath)) {
          path = `${targetFileFolder}`;
        } else if (selectedTopic.folderPath && !refactoredPath) {
          path = `${selectedTopic.folderPath}/${targetFileFolder}`;
        } else {
          path = `${refactoredPath}${targetFileFolder}`;
        }
        const { data } = await uploadFileFolderService.deleteFileFolder(
          topicId,
          path,
          isFolder
        );
        setFilesList([]);
        onPaginationChange(1, pageSize);
        setIsDeleteSuccessFetchApiCall(true);
        openNotificationWithIcon("", data.data.message, "success");
      } catch (error: any) {
        openNotificationWithIcon("", error.response.data.message, "error");
      } finally {
        dispatch(updateDeleteConfirmation(false));
        setIsLoading(false);
      }
    };
    if (isDeleteConfirmed) deleteFileFolder();
  }, [
    directoryPath,
    dispatch,
    isDeleteConfirmed,
    onPaginationChange,
    pageSize,
    topicId,
    setFilesList,
    setIsLoading,
    targetFileFolder,
  ]);

  useEffect(() => {
    if (isDeleteSuccessFetchApiCall) {
      dispatch(updateCanFetchFiles(true));
      setIsDeleteSuccessFetchApiCall(false);
    }
  }, [dispatch, isDeleteSuccessFetchApiCall]);

  const data: IUploadedDocumentsDataType[] = list
    .filter((item) => item.name !== "")
    .map((item, index) => ({
      action: "",
      isFolder: item.isFolder,
      updatedOn: getLocalDateTimeStringFromISO(item.updatedAt),
      createdOn: getLocalDateTimeStringFromISO(item.createdAt),
      fileFolder: item.name,
      key: index,
      status: item?.status?.toUpperCase(),
      storage: item.storage,
      path: item.path,
    }));

  const handleRefreshClick = () => {
    setFilesList([]);
    onPaginationChange(1, pageSize);
    dispatch(updateCanFetchFiles(true));
  };

  const handleCreateFolderClick = () => {
    dispatch(updateFolderIsEditCheck(false));
    dispatch(
      openModal({
        modalName: MODAL_TYPE.ADD_EDIT_FOLDER,
        dataSource: "",
      })
    );
  };

  const renderFolderBreadCrumbs = () => {
    return (
      <div className="flex gp-10 aic">
        {topicId !== -1 && (
          <div
            className="cursor-pointer flex aic gp-10"
            onClick={() => {
              setDirectoryPath([]);
              onPaginationChange(1, pageSize);
              setFilesList([]);
              dispatch(updateCanFetchFiles(true));
              dispatch(updateFolderPath(selectedTopic.folderPath))
            }}
          >
            <FolderFilled className="filled-folder" />
            <h4 className="font-Semibold">{`${selectedTopic.value}`}</h4>
            {directoryPath.length === 1 && <p>{" /"}</p>}
          </div>
        )}
        {directoryPath.map((item, index) => (
          <span
            className="flex aic gp-10 font-14 cursor-pointer"
            key={index}
            onClick={() => {
              setDirectoryPath(directoryPath.slice(0, index + 1));
              onPaginationChange(1, pageSize);
              setFilesList([]);
              dispatch(updateCanFetchFiles(true));
              dispatch(updateFolderPath(item.path))
            }}
          >
            {project.storage==="sharepoint"? item.name: (item.path?.includes("/")? item.path.split("/").slice(-2): item.path)}
            {index === directoryPath.length - 1 ? "" : <p> {"/"}</p>}
          </span>
        ))}
        {project.type === PROJECT_TYPE.MULTI_DOC_QA && (
          <Button className="fill" disabled={false} onClick={handleCreateFolderClick}>
            Create Folder
          </Button>
        )}
      </div>
    );
  };

  const renderActionButtons = () => {
    return (
      <div className="d-flex gp-10">
        <Button
          disabled={topicId === -1 || isLoading}
          className="fill bold"
          title="refresh"
          onClick={handleRefreshClick}
        >
          <ReloadOutlined /> Refresh
        </Button>
        {/* {list.length !== totalFiles && (
          <Button
            className="outline"
            disabled={topicId === -1 || isLoading}
            onClick={() => {
              onPaginationChange(page + 1, pageSize);
              dispatch(updateCanFetchFiles(true));
            }}
          >
            View More
          </Button>
        )} */}
      </div>
    );
  };

  const renderUploadedFilesTable = () => {
    return (
      <div className="">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {list.length ? (
              <Table
                rowClassName={"cursor-pointer"}
                loading={isLoading}
                columns={getColumns(
                  setDirectoryPath,
                  dispatch,
                  setFilesList,
                  onPaginationChange,
                  pageSize
                )}
                pagination={false}
                dataSource={data}
              />
            ) : (
              <>
                {topicId === -1 ? (
                  <Empty>No projects.</Empty>
                ) : (
                  <Empty>This folder is empty.</Empty>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const renderStatusDropdown = () => {
    return (
      <>
        <div className="flex aic gp">
          <h3>Status :</h3>
          <FileStatusDropdown
            {...{
              isStatusLoading,
              statuses,
              selectedStatus,
              fetchStatus,
              handleStatusChange,
            }}
          />
        </div>
      </>
    );
  };
  
  return (  
    <div className="dataset-upload-table">
      <div className="d-flex jcsb">
        {renderFolderBreadCrumbs()}
        <div className="d-flex gp-40">
          <TopicsDropdown {...{ selectedTopic, setSelectedTopic, handleProjectChange }} />
          {isViewFilesFoldersAuthorized && renderStatusDropdown()}
          {renderActionButtons()}
        </div>
      </div>
      {!isViewFilesFoldersAuthorized ? <NotAccessibleFallback /> : renderUploadedFilesTable()}
    </div>
  );
};
