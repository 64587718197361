import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IQuestAns } from "../../interfaces/chat";
import { ISources } from "../../api";

interface IQuesAnsInitialState {
  quesAns: IQuestAns[];
  currentSource: ISources;
}

export const quesAnsInitialState: IQuesAnsInitialState = {
  quesAns: [] as IQuestAns[],
  currentSource: {} as ISources,
};

export const quesAnsSlice = createSlice({
  name: "quesAns",
  initialState: quesAnsInitialState,
  reducers: {
    setQuestionsAnswers: (state: IQuesAnsInitialState, { payload }: PayloadAction<IQuestAns[]>) => {
      state.quesAns = [...payload];
    },
    resetQuestionAnswerState: (state: IQuesAnsInitialState) => {
      state.quesAns = quesAnsInitialState.quesAns;
    },
    setCurrentSource: (state: IQuesAnsInitialState, { payload }: PayloadAction<ISources>) => {
      state.currentSource = payload;
    },
    updateQuestionAnswer(
      state,
      action: PayloadAction<{
        index: number;
        question: string;
        answer: string;
        logId: number;
        sources: ISources[];
        qaId: string;
      }>
    ) {
      const { index, question, answer, logId, sources, qaId } = action.payload;
      if (index >= 0 && index < state.quesAns.length) {
        state.quesAns[index] = { question, answer, logId, sources, qaId };
      }
    }
  },
});

export const { setQuestionsAnswers, resetQuestionAnswerState, setCurrentSource ,updateQuestionAnswer} =
  quesAnsSlice.actions;
export default quesAnsSlice.reducer;
