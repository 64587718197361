import React from "react";
import { Input } from "antd";
import { InformationIcon } from "../../../../assets/icons";

interface Props {
  param: IProjectRetrievalParametersSetting;
  isEdit: boolean;
  editConfiguration: (obj: IProjectRetrievalParametersSetting) => void;
}

export const CommonParamConfigurationSection = (props: Props) => {
  const { editConfiguration, isEdit, param } = props;

  const setValue = (inputValue: string) => {
    editConfiguration({ ...param, value: JSON.stringify({ value: inputValue }) });
  };

  return (
    <>
      <div className="flex gp aic">
        <p>
          {param.name}
          <br />
        </p>
        {param.description && (
          <div className="flex aic" title={param.description || ""}>
            <InformationIcon />
          </div>
        )}
      </div>
      <Input
        disabled={!isEdit}
        className="tc"
        value={JSON.parse(param.value).value}
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
};