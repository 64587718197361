import { IRepsonseWrapper } from ".";
import { apiClient } from "./apiClient";

const getChunkSizes = async () => {
  const res = await apiClient.get<IRepsonseWrapper<DocumentIngestionSetting[]>>(
    "/setting/chunk-size"
  );
  return res;
};

const getChunkingStrategy = async () => {
  const res = await apiClient.get<IRepsonseWrapper<DocumentIngestionSetting[]>>(
    "/setting/chunking-strategy"
  );
  return res;
};

const getEmbeddingModels = async () => {
  const res = await apiClient.get<IRepsonseWrapper<DocumentIngestionSetting[]>>(
    "/setting/data-embedding"
  );
  return res;
};

const getProjectTypes = async () => {
  const res = await apiClient.get<IRepsonseWrapper<ProjectType[]>>("/setting/project-type");
  return res;
};

const getGenerativeAiModel = async () => {
  const res = await apiClient.get<IRepsonseWrapper<IProjectTypeSetting[]>>(
    "model/generative-ai-model"
  );
  return res;
};

const getDocumentIngestionSettings = async (projectId?: string) => {
  const res = await apiClient.get<IRepsonseWrapper<IProjectDocumentIngestionSetting>>(
    `/setting/projects/${projectId}/document-ingestion`
  );
  return res;
};

const getRetrievalParametersSettings = async (projectId?: string) => {
  const res = await apiClient.get<IRepsonseWrapper<IProjectRetrievalParametersSetting[]>>(
    `/setting/projects/${projectId}/retrieval-parameters`
  );
  return res;
};

const getGenerativeAiParametersSettings = async (projectId?: string) => {
  const res = await apiClient.get<IRepsonseWrapper<ModelData[]>>(
    `/setting/projects/${projectId}/generative-ai`
  );
  return res;
};

const updatePreConfiguredPrompts = async (payload: any, projectId?: string) => {
  const res = await apiClient.put(`/setting/projects/${projectId}/prompts`, payload);
  return res;
};

const updateDataFields = async (payload: any, projectId?: string) => {
  const res = await apiClient.put(`/setting/projects/${projectId}/data-fields`, payload);
  return res;
};

const getQAConfiguration = async (projectId?: string) => {
  const res = await apiClient.get<IRepsonseWrapper<IQAConfiguration>>(
    `/setting/projects/${projectId}/qa-configuration`
  );
  return res;
};

const updateDefaultModel = async (modelId?: number, projectId?: string) => {
  const res = await apiClient.patch(`/projects/${projectId}/settings/default-model`, { modelId });
  return res;
};

const updateGenerativeAiParametersSettings = async (payload?: any, projectId?: string) => {
  const res = await apiClient.put(`/projects/${projectId}/settings/connected-model`, payload);
  return res;
};

const getDefaultMessage = async () => {
  const res = await apiClient.get<IRepsonseWrapper<IQAConfiguration>>(
    `/setting/generative-ai-message`
  );
  return res.data;
};

export const SettingsService = {
  getChunkSizes,
  getChunkingStrategy,
  getEmbeddingModels,
  getGenerativeAiModel,
  getProjectTypes,
  getDocumentIngestionSettings,
  getRetrievalParametersSettings,
  getGenerativeAiParametersSettings,
  updatePreConfiguredPrompts,
  updateDataFields,
  getQAConfiguration,
  getDefaultMessage,
  updateDefaultModel,
  updateGenerativeAiParametersSettings,
};
