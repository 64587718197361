import axios from "axios";
import { apiClient } from "./apiClient";
import { IRepsonseWrapper } from ".";
import { SharePointClient } from "./sharepoint";
export interface GetUploadSignedUrl {
  data: {
    signedUrl: string;
    accessToken?: string;
  };
  status: string;
}
export interface FileFolder {
  path: string;
  createdAt: string;
  updatedAt: string;
  isFolder: boolean;
  name: string;
  status: string;
  storage: string;
  documentUuid: string;
}

export interface IGetFileOCRRequest {
  pageNum: number;
  fileUuid: string;
}

export interface IGetFileOCRResponse {
  fileId: string;
  index_filter: string;
  ocr_text: string;
  pageNum: string;
}

export interface IGetFileOCRResponseWrapper {
  data: IGetFileOCRResponse;
  status: string;
}

// export interface IGetFilesResponse {
//   message: string;
//   data: { files: FileFolder[] };
// }

export interface GetFilesAndFolderResponse {
  data: FileFolder[];
  status: string;
  pagination: {
    // continuationToken: string;
    limit: number;
    offset: number;
    total: number;
  };
}

export interface IGetSignedURLForFullSourceResponse {
  data: {
    signedUrl: string;
    filePath: string;
  };
  status: string;
}

export interface IGetMetadataResponse {
  data: { content: string };
  status: string;
}

export interface IUpdateFileFolderMetadataResponse {
  data: { message: string };
  status: string;
}

export interface IDeleteFileFolderResponse {
  data: {
    message: string;
  };
  status: string;
}

export interface IStartProcessingPreUploadedFilesResponse {
  data: {
    message: string;
  };
  status: string;
}

export interface IGetUnprocessedContainersResponse {
  data: string[];
  status: number;
}

export interface IGetFileStatusResponse {
  code: number;
  createdAt: string;
  createdBy: string;
  id: number;
  isDeleted: boolean;
  name: string;
  updatedAt: string;
  updatedBy: string;
}

// const getFiles = async (projectId: number, path?: string) => {
//   const url = path ? `/storage/files/${projectId}?path=${path}` : `/storage/files/${projectId}`;
//   const res = await apiClient.get<IGetFilesResponse>(url);
//   return res;
// };

const getUploadSignedUrl = async (topicId: number, path: string, storage: string) => {
  const res = await apiClient.get<GetUploadSignedUrl>(
    `/storage/signed-url/upload/${topicId}?path=${path}&storage=${storage}`
  );
  return res;
};

const uploadFilesSharePoint = async (
  data: { signedUrl: string; accessToken?: string },
  file: any
) => {
  // Upload logic
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      if (event.target && event.target.result) {
        const arrayBuffer = event.target.result;
        try {
          const res = await axios.put(data.signedUrl, arrayBuffer, {
            headers: {
              "Content-Type": "application/octet-stream",
              Authorization: `Bearer ${data?.accessToken}`,
            },
          });
          resolve(res);
        } catch (error: any) {
          console.error("Failed to upload file:", error);
          alert("Upload failed: " + error.message);
        }
      } else {
        reject(new Error("Failed to read file."));
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};
// This previousy was setting every file to be uploaded as form data,
// but since it is only doing one at a time we can explicitly set the type derived rom the fileObj
const uploadFiles = async (url: string, file: any) => {
  try {
    // Assuming file is an UploadFile from antd, and originFileObj is the native File object
    const fileData = file.originFileObj || file; // The actual File object for upload
    const res = await axios({
      method: "put",
      url,
      data: fileData,
      headers: {
        "Content-Type": fileData.type,
        "x-ms-blob-type": "BlockBlob",
      },
    });
    return res;
  } catch (error) {
    console.error("Error uploading file", error);
    throw error;
  }
};

const startFileProcessing = async (topicId: number, filepath: string, projectId: string) => {
  const res = apiClient.post("/process-file/start", { topicId, filepaths: [filepath], projectId });
  return res;
};

const getFilesAndFolder = async (
  topicId: number,
  path = "",
  limit: number,
  offset: number,
  status: number | string
) => {
  const res = apiClient.get<GetFilesAndFolderResponse>(`/file/topic/${topicId}`, {
    params: { path, status, offset, limit },
  });
  return res;
};

const getAllFilesFromTopic = async(topicId: number,
  limit: number,
  offset: number,
  status: number | string) => {
  const res = apiClient.get<GetFilesAndFolderResponse>(`/file/topic/${topicId}/processed`, {
    params: { offset, limit, status },
  });
  return res;
}

const createFolder = async (topicId: number, folderName: string, metadata: string) => {
  const res = apiClient.post(`/storage/folder/${topicId}`, { folderName, metadata });
  return res;
};

const getSignedURLForFullSource = async (fileUuid: string) => {
  const res = apiClient.get<IGetSignedURLForFullSourceResponse>(
    `/storage/signed-url/read/file/${fileUuid}`
  );
  return res;
};

const getSignedUrlByFilepath = async (topicId: number, path: string, storage?: string) => {
  const res = apiClient.post<IGetSignedURLForFullSourceResponse>(
    `/storage/signed-url/read/topic/${topicId}?path=${path}&storage=${storage}`
  );
  return res;
};

const getSignedUrlByUuid = async (documetUuid: string) => {
  const res = await apiClient.get<IGetSignedURLForFullSourceResponse>(
    `/storage/signed-url/read/file/${documetUuid}`
  );
  return res.data;
};

const getFileFolderMetadata = async (topicId: number, path: string) => {
  const res = await apiClient.get<IGetMetadataResponse>(
    `/storage/meta-data/${topicId}?path=${path}`
  );
  return res;
};

const updateFileFolderMetadata = async (topicId: number, path: string, content: string) => {
  const res = await apiClient.put<IUpdateFileFolderMetadataResponse>(
    `/storage/meta-data/${topicId}`,
    {
      path,
      content,
    }
  );
  return res;
};

const deleteFileFolder = async (topicId: number, path: string, isFolder: boolean) => {
  const res = await apiClient.delete<IDeleteFileFolderResponse>(
    `/storage/folder-file/${topicId}?path=${path}&isFolder=${isFolder}`
  );
  return res;
};

const getFileOCR = async (reqObj: IGetFileOCRRequest) => {
  const res = await apiClient.post<IGetFileOCRResponseWrapper>(`/file/get-ocr`, reqObj);
  return res;
};

const startProcessingPreUploadedFiles = async (topicId: number, dataSource?: string) => {
  const query = dataSource === "sharepoint" ? `?storage=${dataSource}` : `?storage=azure`;
  const res = await apiClient.get<IStartProcessingPreUploadedFilesResponse>(
    `/process-file/preuploaded-files/add/${topicId}${query}`
  );
  return res;
};

const getUnprocessedContainers = async (dataSource?: string) => {
  const query = dataSource ? `?storage=${dataSource}` : `?storage=azure`;
  const res = await apiClient.get<IGetUnprocessedContainersResponse>(
    `/storage/projects/non-processed${query}`
  );
  return res;
};

const getFileStatus = async () => {
  const res = await apiClient.get<IRepsonseWrapper<IGetFileStatusResponse[]>>(
    "/process-file/status"
  );
  return res;
};

export const uploadFileFolderService = {
  getUploadSignedUrl,
  uploadFiles,
  uploadFilesSharePoint,
  // getFiles,
  getFilesAndFolder,
  getAllFilesFromTopic,
  startFileProcessing,
  createFolder,
  getSignedURLForFullSource,
  getSignedUrlByFilepath,
  getFileFolderMetadata,
  updateFileFolderMetadata,
  deleteFileFolder,
  getFileOCR,
  startProcessingPreUploadedFiles,
  getUnprocessedContainers,
  getFileStatus,
  getSignedUrlByUuid,
};
