import { Tabs } from "antd";
import React from "react";
import { DATASET_TABS_MULTI, DATASET_TABS_SINGLE } from "..";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { PROJECT_TYPE } from "../../../utils";

const { TabPane } = Tabs;

interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
}

export const DatasetTabs = ({ onChange, selectedTab }: Props) => {
  const showSelectADataSourceTab =
    process.env.REACT_APP_DISPLAY_SELECT_A_DATA_SOURCE_TAB &&
    process.env.REACT_APP_DISPLAY_SELECT_A_DATA_SOURCE_TAB === "true";
  
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  return (
    <div className="tabs m-b">
      <Tabs onChange={onChange} activeKey={selectedTab}>
        {Object.values(
          project.type === PROJECT_TYPE.MULTI_DOC_QA ? DATASET_TABS_MULTI : DATASET_TABS_SINGLE
        ).map((key) => {
          if (key === DATASET_TABS_MULTI.SELECT_DATA_SOURCE_URL && !showSelectADataSourceTab)
            return;
          return <TabPane tab={key} key={key} />;
        })}
      </Tabs>
    </div>
  );
};
