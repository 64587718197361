export enum USER_ROLES_CODE {
  ADMIN = "SUPER_ADMIN",
  DATA_SCIENTIST = "DATA_SCIENTIST",
  BUSINESS_USER = "BUSINESS_USER",
}



export enum MODAL_TYPE {
  ADD_EDIT_PROJECT = "addEditProject",
  DELETE_CONFIRMATION = "deleteConfirmation",
  EDIT_PROFILE = "editProfile",
  FORGOT_PASSWORD = "forgotPassword",
  ADD_AND_SIGN_UP_USER = "addAndSignUpUser",
  APPROVE_CONFIRMATION = "approveConfirmation",
  FEEDBACK = "feedback",
  ADD_EDIT_FOLDER = "addEditFolder",
  VIEW_FULL_SOURCE = "viewFullSource",
  DATA_SOURCE = "dataSource",
  EDIT_FILE = "editFile",
  ADD_PROJECT = "addProject",
  NO_PROJECTS_WARNING = "noProjectsWarning",
  LICENSE_KEY = "licenseKey",
  CONNECT_TO_DATA_SOURCE = "connectToDataSource",
  PROJECTS = "projects",
  EDIT_PROJECTS = "editProjects",
  SOURCE_CONFIRMATION="sourceConfirmation"
}

export enum THEME {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum TASK_COLLAPSIBLE_COMPONENT_HEADING {
  TASK_DESCRIPTION = "Task Description",
}

export enum DOCUMENT_STATUS {
  PROCESSING = "PROCESSING",
  READY = "READY",
  FAILED = "FAILED",
}

export enum STATUS_CODE {
  FORBIDDEN = 403,
}

export enum PROJECT {
  PROVIDER_CONTRACTS = "Provider Contracts",
  PAYMENT_INTEGRITY = "Payment Integrity",
  PROVIDER_CONTRACTS_CONFIGURATION = "Provider Contracts - Configuration"
} 

export enum PROJECT_TYPE {
  SINGLE_DOC_QA = "Single-Doc QA",
  MULTI_DOC_QA = "Multi-Doc QA"
}

export enum PROJECT_TYPE_SETTINGS {
  DATA_FIELDS = "data-fields",
  PRECONFIGURED_PROMTPS = "preconfigured-prompts"
}

export const STORAGE_TYPES = {
  AZURE: 'azure',
  SHAREPOINT: 'sharepoint'
} as const;