import { Dispatch } from "redux";
import { ISources } from "../../../api";
import { RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import { ViewCompleteSource } from "./ViewCompleteSource";

type StateProps = {
  visibility: boolean;
  source: ISources;
};

type DispatchProps = {
  handleClose: () => void;
};

export type ViewFullSourceProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.VIEW_FULL_SOURCE],
  source: state.quesAns.currentSource,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.VIEW_FULL_SOURCE));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCompleteSource);
