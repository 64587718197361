import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Dropdown } from "../Dropdown";
import { useProject } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateFolderPath, updateTopicId } from "../../store";
import { PROJECT_TYPE } from "../../utils";
import { useParams } from "react-router-dom";

interface Props {
  selectedTopic: IDropdownOption;
  setSelectedTopic: Dispatch<SetStateAction<IDropdownOption>>;
  handleProjectChange: (e: string, option: IDropdownOption, topics: ITopic[]) => void;
  isProvider?: boolean;
}

export const TopicsDropdown = (props: Props) => {
  const { selectedTopic, setSelectedTopic, handleProjectChange, isProvider } = props;
  const { fetchTopics, loading, topics } = useProject();
  const dispatch = useDispatch();
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  const { projectId } = useParams();

  useEffect(() => {
    fetchTopics(projectId);
  }, [fetchTopics]);

  useEffect(() => {
    if (topics.length > 0) {
      setSelectedTopic({ id: topics[0].id, value: topics[0].displayName});
      dispatch(updateTopicId(topics[0].id));
      dispatch(updateFolderPath(topics[0].folderPath))
      // dispatch(updateProjectType(projects[0].projectType))
    }
  }, [dispatch, topics, setSelectedTopic]);

  const handleOnChangeProject = (e: string, option: IDropdownOption) => {
    handleProjectChange(e, option, topics);
    dispatch(updateTopicId(option.id));
    dispatch(updateFolderPath(option.folderPath))
  };
  if (loading || topics.length <= 1) return <></>;

  return !isProvider ? (
    <div className="flex gp aic">
      {project.type === PROJECT_TYPE.MULTI_DOC_QA && (
        <>
          <h3 className="font-Semibold">Topic:</h3>
          <Dropdown
            options={topics.map((topic) => ({
              value: topic.displayName,
              id: topic.id,
              key: topic.id,
              storage: topic.storage,
              folderPath:topic.folderPath
            }))}
            loading={loading}
            onSelect={(e, options) => handleOnChangeProject(e, options)}
            value={selectedTopic.value}
            style={{ width: 200 }}
          />
        </>
      )}
    </div>
  ) : null;
};
