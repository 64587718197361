import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppPagination, Dropdown } from "../../components";
import { useFetchUsers } from "../../hooks/useFetchUsers";
import { openModal, RootState, setIsUserApproved } from "../../store";
import { MODAL_TYPE, USERS_DROPDOWN_OPTIONS } from "../../utils";
import { ApprovedUsers, ToBeApprovedUsers } from "./users-container";

export const UsersContainer = () => {
  const {
    isLoading,
    fetchUsers,
    users,
    total,
    pageSizeOptions,
    onPaginationChange,
    page,
    pageSize,
  } = useFetchUsers();
  const [userTypeSeleceted, setUserTypeSeleceted] = useState(USERS_DROPDOWN_OPTIONS[0].value);
  const [component, setComponent] = useState(<></>);
  const dispatch = useDispatch();
  const { isUserApproved } = useSelector((state: RootState) => state.userApproval);

  useEffect(() => {
    fetchUsers(userTypeSeleceted === USERS_DROPDOWN_OPTIONS[0].value ? false : true);
  }, [fetchUsers, userTypeSeleceted]);

  useEffect(() => {
    if (isUserApproved) {
      dispatch(setIsUserApproved(false));
      fetchUsers(userTypeSeleceted === USERS_DROPDOWN_OPTIONS[0].value ? false : true);
    }
  }, [isUserApproved, fetchUsers, userTypeSeleceted, dispatch]);

  useEffect(() => {
    let res = <></>;
    switch (userTypeSeleceted) {
      case USERS_DROPDOWN_OPTIONS[1].value:
        res = <ApprovedUsers {...{ isLoading, users }} />;
        break;
      case USERS_DROPDOWN_OPTIONS[0].value:
        res = <ToBeApprovedUsers {...{ isLoading, users }} />;
        break;
    }
    setComponent(res);
  }, [userTypeSeleceted, users, isLoading]);

  const handleUserTypeChange = (value: string) => {
    onPaginationChange(1, pageSize);
    setUserTypeSeleceted(value);
  };

  return (
    <>
      <div className="flex jcsb aic">
        <div className="flex aic gp">
          <h4>Users</h4>
          <Button
            className="outline"
            onClick={() => dispatch(openModal({
              modalName: MODAL_TYPE.ADD_AND_SIGN_UP_USER,
              dataSource: "",
            }))}
          >
            Add New User
          </Button>
        </div>
        <div className="flex gp aic">
          <Dropdown
            style={{ width: 210 }}
            defaultValue={userTypeSeleceted}
            options={USERS_DROPDOWN_OPTIONS}
            onSelect={handleUserTypeChange}
          />
          <AppPagination
            {...{
              showSizeChanger: true,
              current: page,
              total,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange,
            }}
          />
        </div>
      </div>
      {component}
    </>
  );
};
