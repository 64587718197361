export * from "./DatasetContainer";

export const DATASET_TABS_MULTI = {
  UPLOAD_DOCUMENTS: "Upload Documents",
  SELECT_DATA_SOURCE_URL: "Select Data Source URL",
  CONNECT_TO_DATA_SOURCE: "Connect to Data Source",
  TOPICS_LIST: "Topics List"
};

export const DATASET_TABS_SINGLE = {
  UPLOAD_DOCUMENTS: "Upload Documents",
  SELECT_DATA_SOURCE_URL: "Select Data Source URL",
  CONNECT_TO_DATA_SOURCE: "Connect to Data Source",
};
