import React, { useCallback, useEffect, useState } from "react";
import { useProjectSettings } from "../../../../hooks";
import {
  GenerativeAIConfigurationSection,
  GeneraticeAIConfigurationSystemMessageSection,
} from "./GenerativeAIConfigurationSection";
import { EditSettingsButton } from "../EditSettingsButton";
import { Button, Tag, Modal } from "antd";
import { SettingsService } from "../../../../api";
import { CloseOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from "../../../../utils";

interface Props {
  querySettings: ModelData[];
  projectId: string;
  selectedTab: string;
}

const GENERATIVE_AI_PARAMS = "QS_GENERATIVE_AI_PARAM";
const SYSTEM_MESSAGE_PARAM = "QS_GENERATIVE_AI_PARAM_SYSTEM_MESSAGE";

export const ModelSettings = (props: Props) => {
  const { projectId, querySettings, selectedTab } = props;
  const {
    updateQuerySettings,
    updateSettingLoading,
    fetchGenerativeAiParametersSettings,
    generativeAiParametersSettings,
  } = useProjectSettings();
  const [isEdit, setIsEdit] = useState(false);
  const [localUpdatedParams, setLocalUpdatedParams] = useState<ModelData[]>([]);
  const [selectedModel, setSelectedModel] = useState<ModelData>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const editConfiguration = useCallback(
    (obj: Setting) => {
      if (!selectedModel) return;
      const updatedSettings = selectedModel.setting.map((setting) => {
        if (setting.id === obj.id) {
          return { ...setting, ...obj };
        }
        return setting;
      });
      setSelectedModel({ modelId: selectedModel.modelId, setting: updatedSettings });
    },
    [selectedModel]
  );

  const setParams = useCallback(() => {
    const params = querySettings.filter((item) =>
      item.setting?.some((setting) => setting.name.includes(GENERATIVE_AI_PARAMS))
    );
    const index = params.findIndex((item) =>
      item.setting?.some((setting) => setting.name === SYSTEM_MESSAGE_PARAM)
    );
    setLocalUpdatedParams(params);
  }, [querySettings]);

  useEffect(() => {
    setParams();
  }, [setParams]);

  const handleCancelClick = useCallback(() => {
    setIsEdit((prev) => !prev);
    setParams();
  }, [setParams]);

  const handleEditClick = useCallback(async () => {
    if (isEdit) {
      await SettingsService.updateGenerativeAiParametersSettings(selectedModel, projectId);
      openNotificationWithIcon("", "Generative AI Params Settings updated successfully", "success");
    }
    setIsEdit((prevValue) => !prevValue);
  }, [isEdit, localUpdatedParams, projectId, updateQuerySettings, selectedModel]);

  const getConfirmationModal = async (modelId?: number) => {
    setShowConfirmationModal(true);
  };

  const handleSelectedModel = (settings: ModelData[]) => {
    const data = settings.find((item) => item.name === selectedTab);
    setSelectedModel(data);
  }

  useEffect(() => {
    if (generativeAiParametersSettings.length) {
      handleSelectedModel(generativeAiParametersSettings)
    } else {
      handleSelectedModel(querySettings)
    }
  }, [selectedTab, generativeAiParametersSettings]);

  useEffect(() => {
    if (!showConfirmationModal && generativeAiParametersSettings.length) {
      handleSelectedModel(generativeAiParametersSettings)
    }
  }, [showConfirmationModal]);

  const handleSetDefault = async () => {
    const { data } = await SettingsService.updateDefaultModel(selectedModel?.modelId, projectId);
    await fetchGenerativeAiParametersSettings(projectId);
    setSelectedModel(generativeAiParametersSettings.find((item) => item.name === selectedTab));
    openNotificationWithIcon("", data.data.message, "success");
    setShowConfirmationModal(false);
  };

  const renderConfirmationModal = () => {
    return (
      <Modal
        className="autonomizeModal"
        centered
        width={690}
        title=""
        open={showConfirmationModal}
        footer={null}
        closeIcon={<CloseOutlined onClick={() => setShowConfirmationModal(false)} />}
      >
        <div className="addClient-title">
          <h1>{"Confirmation"}</h1>
        </div>
        <div className="deleteConfirmation tc addClient-content">
          <p>Are you sure you want to make this your default model ?</p>
          <div className="flex jcc gp">
            <Button className="outline" onClick={() => setShowConfirmationModal(false)}>
              No
            </Button>
            <Button className="outline" onClick={handleSetDefault}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <div className="d-flex jce">
        {selectedModel?.isDefault ? (
          <Tag style={{ fontSize: "16px", padding: "8px 16px" }} color="green">
            Default Model
          </Tag>
        ) : (
          <Button className="outline" onClick={() => getConfirmationModal(selectedModel?.modelId)}>
            Set as Default
          </Button>
        )}
      </div>
      <div className="grid grid-cols-2 gp">
        {selectedModel?.setting
          ?.filter(
            (setting) => setting.name === "System Message" || setting.name === "User Message"
          )
          .map((item, index) => (
            <div key={`message-${index}`}>
              <GeneraticeAIConfigurationSystemMessageSection
                isEdit={isEdit}
                editConfiguration={editConfiguration}
                param={item}
              />
            </div>
          ))}
      </div>
      <h4 className="m-t m-b">Parameters</h4>
      <div className="grid grid-cols-2 gp">
        {selectedModel?.setting
          ?.filter(
            (setting) => setting.name !== "System Message" && setting.name !== "User Message"
          )
          .map((item, index) => (
            <div key={`parameter-${index}`} className="box-group grid aic column-right-50">
              <GenerativeAIConfigurationSection
                isEdit={isEdit}
                editConfiguration={editConfiguration}
                param={item}
              />
            </div>
          ))}
      </div>
      <EditSettingsButton
        {...{
          handleEdit: handleEditClick,
          isEdit,
          setIsEdit,
          loading: updateSettingLoading,
          handleCancel: handleCancelClick,
        }}
      />
      {renderConfirmationModal()}
    </div>
  );
};
