import React, { useCallback, useEffect, useState } from "react";
import {
  DATA_EMBEDDING_MODELS_DESCRIPTION,
  STATUS_CODE,
  openNotificationWithIcon,
} from "../../../utils";
import { TopicsService } from "../../../api/topic";
import { Button, Modal, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ConnectToDataSourceProps } from ".";
import { uploadFileFolderService } from "../../../api";
import { Dropdown } from "../../../components";
import { RootState, updateProject, updateStorage } from "../../../store";
import { DATASET_TABS_MULTI } from "../../dataset";
import { useDispatch, useSelector } from "react-redux";
import { useProject } from "../../../hooks";

const ConnectToDatSource = ({ handleClose, visibility, dataSource }: ConnectToDataSourceProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [connectingToDataSourceLoading, setConnectingToDataSourceLoading] = useState(false);
  const [containers, setContainers] = useState([] as string[]);
  const [topicName, setTopicName] = useState(""); // Add state for project name
  const { fetchTopics} = useProject();
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  
  const temp = useSelector((state:RootState)=>state.fileFolderUpload)
  const [selectedProject, setSelectedProject] = useState({
    id: -1,
    value: "Select a Data Source",
  } as IDropdownOption);
  const [selectedEmbeddingModel, setSelectedEmbeddingModel] = useState({
    id: -1,
    value: "Choose a project",
  } as IDropdownOption);
  const dispatch = useDispatch();

  const fetchNonProcessedContainers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await uploadFileFolderService.getUnprocessedContainers(dataSource);
      setContainers(data.data);
      if (data.data.length === 0) {
        openNotificationWithIcon("", "No containers found", "error");
        handleClose();
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        handleClose();
      }
    } finally {
      setIsLoading(false);
    }
  }, [handleClose]);

  useEffect(() => {
    const fetchDataSource = async () => {
      await fetchNonProcessedContainers();
    };
    fetchDataSource();
  }, [fetchNonProcessedContainers]);
  
  const createTopicAndIngestDataButtonClick = async () => {
    try {
      setConnectingToDataSourceLoading(true);
      const { data } = await TopicsService.createTopics(
        topicName,
        dataSource,
        project.uuid,
        false,
        selectedProject.value,
      );
      
      dispatch(updateProject({
        ...project,
        storage: dataSource,
        sourceName: selectedProject.value
      }));
      openNotificationWithIcon(
        "",
        `${topicName} topic created successfully. Fetching file from the data source`,
        "success"
      );
      // const { data: preprocessingFilesData } =
      //   await uploadFileFolderService.startProcessingPreUploadedFiles(data.data.id, dataSource);
      // openNotificationWithIcon("", preprocessingFilesData.data.message, "success");
      handleClose();
      fetchTopics();
      dispatch(updateStorage(""));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setConnectingToDataSourceLoading(false);
      dispatch(updateStorage(""));
    }
  };
  
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1>{DATASET_TABS_MULTI.CONNECT_TO_DATA_SOURCE}</h1>
      </div>
      <div className="addClient-content modal-form-content">
        <div className="single-content">
          {/* <p className="font-Semibold">Topic Name : </p>
          <Input
          placeholder="Enter Topic Name"
            value={projectName}
            onChange={(e) => setTopicName(e.target.value)}
          /> */}
        </div>
        <div className="flex aic jcsb m-b">
          <p className="font-Semibold">Data Source : </p>
        </div>
        <div className="m-b" style={{height:'200px'}}>
          <Dropdown
            options={containers.map((container, index) => ({
              value: container,
              id: index,
              key: index,
            }))}
            loading={isLoading}
            onSelect={(e, option) => {
              setTopicName(e);
              setSelectedProject(option);
            }}
            value={selectedProject.value}
          />
        </div>
        <div className="m-t flex aic gp-10 jce">
          <Button
            className="outline"
            onClick={createTopicAndIngestDataButtonClick}
            disabled={topicName.trim() === "" || temp.topicId !== -1}
            loading={connectingToDataSourceLoading}
          >
            Select Data Source
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectToDatSource;
