import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { authService } from "../../../api";

interface Props {
  setEmail: Dispatch<SetStateAction<string>>;
}

export const ForgotPasswordForm = ({ setEmail }: Props) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [disableSave, setDisableSave] = useState(true);

  const handleFormSubmit = async (e: any) => {
    setLoading(true);
    setError("");
    const email = e.target[0].value;
    try {
      await authService.forgotPassword({ email });
      setEmail(email);
    } catch (err: any) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  return (
    <Form
      onSubmitCapture={(e) => handleFormSubmit(e)}
      onFieldsChange={handleFormChange}
      form={form}
      colon={false}
      requiredMark={false}
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Please enter your email" },
          { type: "email", message: "Please enter valid email" },
        ]}
      >
        <div>
          <p className="m-b">
            To reset your password, please enter your registered email below and we will send a link
            to reset your password to the email.
          </p>
          <Input placeholder="Enter email address" type="email" />
        </div>
      </Form.Item>
      {error && <h3 className="error">{error}</h3>}
      <Form.Item className="tr">
        <Button className="fill" htmlType="submit" loading={loading} disabled={disableSave}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
