import React from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import { GuestLayout } from "./GuestLayout";
import { PrivateLayout } from "./PrivateLayout";
import { PAGE_URL } from "../utils";
import * as Pages from "../pages";
import { RedirectLayout } from "./RedirectLayout";
import ProjectRoutes from "./ProjectRoutes";

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<RedirectLayout />}>
        <Route path={PAGE_URL.REDIRECTION} element={<Pages.Redirect />} />
      </Route>
      <Route element={<PrivateLayout />}>
        <Route path={PAGE_URL.DASHBOARD} element={<Pages.Projects />} />
        <Route path={`${PAGE_URL.CREATE_PROJECTS}`} element={<Pages.CreateProjects />} />
        <Route path={`${PAGE_URL.PROJECTS}/:projectId/*`} element={<ProjectRoutes />} />
      </Route>
      <Route element={<GuestLayout />}>
        <Route path={PAGE_URL.REDIRECTION} element={<Pages.Redirect />}></Route>
        <Route path={PAGE_URL.LOGIN} element={<Pages.Login />} />
        <Route path={PAGE_URL.RESET_PASSWORD} element={<Pages.ResetPassword.Form />} />
        <Route path={PAGE_URL.RESET_PASSWORD_SUCCESS} element={<Pages.ResetPassword.Success />} />
        <Route path={PAGE_URL.CREATE_PASSWORD} element={<Pages.CreatePassword.Form />} />
        <Route path={PAGE_URL.CREATE_PASSWORD_SUCCESS} element={<Pages.CreatePassword.Success />} />
      </Route>
    </Routes>
  );
};
