import React, { useEffect } from "react";
import { Dropdown } from "../Dropdown";

interface Props {
  isStatusLoading: boolean;
  statuses: IDropdownOption[];
  selectedStatus: IDropdownOption;
  fetchStatus: () => Promise<void>;
  handleStatusChange: (e: string, option: IDropdownOption) => void;
}

export const FileStatusDropdown = ({
  fetchStatus,
  handleStatusChange,
  isStatusLoading,
  selectedStatus,
  statuses,
}: Props) => {
  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  return (
    <>
      <Dropdown
        value={selectedStatus.value}
        onSelect={handleStatusChange}
        loading={isStatusLoading}
        options={statuses}
        style={{ width: 200 }}
      />
    </>
  );
};
