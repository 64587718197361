import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { ApproveConfirmationProps } from ".";
import { userService } from "../../../api";
import { isEmptyObject, openNotificationWithIcon } from "../../../utils";

export const ApproveConfirmation = ({
  visibility,
  user,
  handleClose,
  setIsUserApproved,
}: ApproveConfirmationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      if (!isEmptyObject(user)) {
        setIsLoading(true);
        await userService.approveUser(user.id);
        setIsUserApproved();
        openNotificationWithIcon("", "User approved successfully", "success");
        handleClose();
      }
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="Approve User Modal"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title tc">
        <h1>User Approval Confirmation</h1>
      </div>
      <div className="addClient-content tc">
        <h4 className="m-b">Do you want to approve this user?</h4>
        <p>
          Name - {user.firstName} {user.lastName}
        </p>
        <p>Email - {user.email}</p>
        <div className="flex jcc gp m-t m-b">
          <Button type="primary" className="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" className="outline" onClick={handleSubmit}>
            Approve
          </Button>
        </div>
      </div>
    </Modal>
  );
};
