import React, { useState } from "react";
import { ChatAside, ChatContainer, PageWrapper } from "../../containers";
import { ISources } from "../../api";
import { withAuthorization } from "../../components";
import { useOutletContext } from "react-router-dom";
import { IOutletContext } from "../../navigation/PrivateLayout";

const AuthorizedChatContainer = withAuthorization(ChatContainer);

const Chat = () => {
  const [hideChatHistory, setHideChatHistory] = useState(false);
  const [chatSource, setChatSource] = useState({} as ISources);
  const [isQuesAnsLoading, setIsQuesAnsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showChatAside, setShowChatAside] = useState(true);

  return (
    <PageWrapper
      pageWrapperClass={expanded ? "expanded" : ""}
      asideClassName=""
      pageLeftAsideChildren={
        showChatAside ? (
          <ChatAside
            {...{
              hideChatHistory,
              setHideChatHistory,
              chatSource,
              setIsQuesAnsLoading,
              expanded,
              setExpanded,
            }}
          />
        ) : undefined
      }
      pageContainerChildren={
        <AuthorizedChatContainer
          {...{
            setChatSource,
            isQuesAnsLoading,
            setIsQuesAnsLoading,
            setHideChatHistory,
            expanded,
            setExpanded,
            hideChatHistory,
            setShowChatAside,
            showChatAside,
          }}
        />
      }
    />
  );
};

export default Chat;
