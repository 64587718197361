import React, { useState } from "react";

interface DocViewerProps {
  fileUrl: string;
}

export const DocViewer = ({ fileUrl }: DocViewerProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="doc-viewer">
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
        width="100%"
        height="600px"
        frameBorder="0"
        onLoad={handleLoad}
        style={{ display: isLoading ? "none" : "block" }}
      />
    </div>
  );
};
