import React, { useEffect } from "react";
import { useAuthUser } from "../../hooks";
import { PROJECT_TYPE, PAGE_URL, USER_ROLES_CODE } from "../../utils";
import AdminDashboard from "./AdminDashboard";
import Chat from "./Chat";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { PageWrapper } from "../../containers";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import SingleDocAskAuto from "../../containers/projects/SingleDocAskAuto";

const Dashboard = () => {
  const { roleCode } = useAuthUser();
  const { type: projectType } = useSelector(
    (state: RootState) => state.fileFolderUpload.project
  );
  const { documentUuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (projectType === PROJECT_TYPE.MULTI_DOC_QA && documentUuid) {
      navigate(PAGE_URL.DASHBOARD, { replace: true });
    }
  }, [documentUuid]);

  if (roleCode === USER_ROLES_CODE.ADMIN) {
    return <AdminDashboard />; // todo : needs more clarification on where to place this
  }

  return (
    <Routes>
      <Route index element={<Chat />} />
      <Route
        path=":documentUuid"
        element={<PageWrapper pageContainerChildren={<SingleDocAskAuto />} />}
      />
    </Routes>
  );
};

export default Dashboard;
