import React from "react";
import { ProjectsList } from "./settings-container";
import "./setting.scss";

export const SettingsContainer = () => {
  return (
    <div>
      <ProjectsList />
    </div>
  );
};
