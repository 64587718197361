import React, { useEffect, useState } from "react";
import { DATASET_TABS_MULTI } from ".";
import {
  DatasetConnectToDataSource,
  DatasetSelectDataSourceUrl,
  DatasetTabs,
  DatasetUploadDocuments,
} from "./dataset-container";
import { useSearchParams } from "react-router-dom";
import { appendTabToRouteQuery } from "../../utils";
import "./dataset.scss";
import { TopicsDropdown, withAuthorization } from "../../components";
import { SettingsContainer } from "../settings";
import { useProject } from "../../hooks";
import { Alert } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const AuthorizedDatasetUploadDocuments = withAuthorization(DatasetUploadDocuments);

export const DatasetContainer = () => {
  const [selectedTab, setSelectedTab] = useState(DATASET_TABS_MULTI.UPLOAD_DOCUMENTS);
  const [component, setComponent] = useState(<></>);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetchTopics, topics,fetchProjects } = useProject();
  const [showAlert, setShowAlert] = useState(false);
  const { topicId } = useSelector((state: RootState) => state.fileFolderUpload);
  const {project} = useSelector((state: RootState) => state.fileFolderUpload);
  const onChange = (key: string) => {
    setSelectedTab(key);
  };
  
  useEffect(() => {
    const tab = searchParams.get("tab");
    setSelectedTab(tab || DATASET_TABS_MULTI.UPLOAD_DOCUMENTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if (!project.sourceName) {
      setShowAlert(true);
    } else setShowAlert(false)
  }, [project]);

  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case DATASET_TABS_MULTI.UPLOAD_DOCUMENTS:
        res = <AuthorizedDatasetUploadDocuments />;
        break;
      case DATASET_TABS_MULTI.SELECT_DATA_SOURCE_URL:
        res = <DatasetSelectDataSourceUrl />;
        break;
      case DATASET_TABS_MULTI.CONNECT_TO_DATA_SOURCE:
        res = <DatasetConnectToDataSource />;
        break;
      case DATASET_TABS_MULTI.TOPICS_LIST:
        res = <SettingsContainer />;
        break;
    }
    setComponent(res);
    appendTabToRouteQuery(selectedTab);
  }, [selectedTab]);

  return (
    <div>
      {showAlert && (
        <div className="alert-center">
          <Alert
            icon={<WarningOutlined />}
            message="No data source has been selected, please select a data source before asking QA."
            type="error"
            showIcon
          />
        </div>
      )}
      <DatasetTabs {...{ onChange, selectedTab }} />
      {component}
    </div>
  );
};
