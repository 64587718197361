import { Layout } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import { RedirectHeader, RedirectMenu } from "../containers";

export const RedirectLayout = () => {
  return (
    <Layout>
      <RedirectMenu />
      <Layout>
        <RedirectHeader />
        <Outlet />
      </Layout>
    </Layout>
  );
};
