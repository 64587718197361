import { openNotificationWithIcon } from "./notification";
import { isEmptyObject } from "./objectOperations";

export const filteredArray = (
  array: Array<Record<string, unknown>>,
  valueToRemove: unknown,
  filterByKey: string
) => array.filter((item) => item[filterByKey] !== valueToRemove);

export const getExistingSearchParams = (searchParams: URLSearchParams) => {
  const params = [];
  for (const entry of searchParams.entries()) {
    params.push(entry);
  }
  const res: { [key: string]: string } = {};
  params.forEach(([key, value]) => {
    res[key] = value;
  });
  return res;
};

export const hexToRGBColor = (hex: string, alpha = 1) => {
  const match = hex.match(/\w\w/g);
  if (match && match.length) {
    const [r, g, b] = match.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgba(255,255,255,${alpha})`;
};

export const roundToNearestNumber = (value: number, roundToNumber: number) =>
  Math.ceil(value / roundToNumber) * roundToNumber;

export const mergeQueryStrings = (qsArray: string[]): string => {
  if (!qsArray.length) return "";
  return qsArray.join("&");
};

export const getQueryStringFromPaginationObj = (obj: IPagination): string => {
  let qs = "";
  if (isEmptyObject(obj)) return qs;
  const qsArray = Object.entries(obj).map(([key, value]) => `${key}=${value}`);
  qs = mergeQueryStrings(qsArray);
  return qs;
};

export const getOffsetFromPageAndPageSize = (page: number, pageSize: number) =>
  (page - 1) * pageSize;

export const appendTabToRouteQuery = (selectedTab: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set("tab", selectedTab);
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.replaceState(null, "", newUrl);
};

export const createTxtFile = (filename: string, content: string) => {
  const file = new File([content], `${filename}.txt`, { type: "text/plain" });
  return file;
};

export const downloadPDF = async (url: string, filename: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    openNotificationWithIcon("", "Downlaod Failed", "error");
  }
};

export const sanitizeAndConvertToKebabCase = (input: string, addUnixTimeStamp?: boolean) =>
  input
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, "-")
    .concat(addUnixTimeStamp ? `-${Date.now()}` : "")
