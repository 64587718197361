import { Tabs } from "antd";
import React from "react";
import { GENERATIVE_AI_TABS } from "..";

const { TabPane } = Tabs;

interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
}

export const GenerativeAITabs = ({ onChange, selectedTab }: Props) => {
  return (
    <div className="tabs m-b">
      <Tabs tabPosition="left" onChange={onChange} activeKey={selectedTab}>
        {Object.values(GENERATIVE_AI_TABS).map((key) => (
          <TabPane tab={key} key={key} />
        ))}
      </Tabs>
    </div>
  );
};
