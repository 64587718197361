import React from "react";
import { ChatHistory, ChatSource } from "./chat-aside";
import { ISources } from "../../api";
import { CloseOutlined } from "@ant-design/icons";
import { withAuthorization } from "../../components";
import "./chat-aside/chat-aside.scss";

interface Props {
  hideChatHistory: boolean;
  setHideChatHistory: React.Dispatch<React.SetStateAction<boolean>>;
  chatSource: ISources;
  setIsQuesAnsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isAnalysis?: boolean;
  hideCloseIcon?: boolean;
  setCurrPage?: (page: number) => void;
  setShowRightContainer?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChatAside = ({
  setIsQuesAnsLoading,
  setShowRightContainer,
}: Props) => {
  return (
    <>
      <ChatHistory {...{ setIsQuesAnsLoading, setShowRightContainer }} />
      {/* {hideChatHistory && (
        <div className="h-full">
          <div className="flex gp aic jcsb m-b">
            <h4>Source</h4>
            {!isAnalysis && !hideCloseIcon && (
              <CloseOutlined
                onClick={() => {
                  setExpanded(false);
                  setHideChatHistory(false);
                }}
              />
            )}
          </div>
          <AuthorizedChatSource
            {...{ chatSource, setHideChatHistory, expanded, setExpanded, setCurrPage }}
          />
        </div>
      )} */}
    </>
  );
};
