import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import "./login.scss";
import { ActiveDirectoryService } from "../../api";
import { microsoftLogo } from "../../assets/images";
import { setVerifier } from "../../store";

const Login = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmitClick = async () => {
    setIsLoading(true);
    const { data } = await ActiveDirectoryService.activeDirectoryLogin(
      `${window.location.origin}/redirect`,
      `${window.location.origin}/redirect`
    );
    if (data) {
      dispatch(setVerifier(data.data.verifier));
      window.location.assign(data.data.authCodeUrl);
    }
  };

  return (
    <div className="microsoft-text">
      <div>
        <img src={microsoftLogo} />
      </div>
      <h5>Please click on the button below to login</h5>
      <Button className="login-button" loading={isLoading} onClick={handleSubmitClick}>
        Login with Microsoft
      </Button>
    </div>
  );
};

export default Login;
