import React, { useEffect, useState } from "react";
import { EditFileProps } from ".";
import { Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { uploadFileFolderService } from "../../../api";
import { openNotificationWithIcon } from "../../../utils";

export const EditFile = (props: EditFileProps) => {
  const { topicId, targetFilePath, handleClose, visibility } = props;

  const [metadata, setMetadata] = useState("");
  const [originalMetadata, setOriginalMetadata] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getMetadata = async () => {
      setIsLoading(true);
      try {
        const { data } = await uploadFileFolderService.getFileFolderMetadata(
          topicId,
          targetFilePath
        );
        setMetadata(data.data.content);
        setOriginalMetadata(data.data.content);
      } catch (error: any) {
        openNotificationWithIcon("", error.response.data.message, "error");
        handleClose();
      } finally {
        setIsLoading(false);
      }
    };
    getMetadata();
  }, [handleClose, topicId, targetFilePath]);

  const handleUpdateFileMetadataClick = async () => {
    setIsLoading(true);
    try {
      const { data } = await uploadFileFolderService.updateFileFolderMetadata(
        topicId,
        targetFilePath,
        metadata
      );
      openNotificationWithIcon("", "Metadata updated successfully", "success");
      handleClose();
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1>Edit File</h1>
      </div>
      <div className="addClient-content">
        <p className="font-Semibold m-b">Metadata : </p>
        <TextArea
          rows={5}
          placeholder="keywords, version numbers, topics"
          value={metadata}
          onChange={(e) => {
            setMetadata(e.target.value);
          }}
        />
        <div className="tr m-b m-t">
          <Button
            disabled={originalMetadata === metadata && !isLoading}
            loading={isLoading}
            className="fill"
            onClick={handleUpdateFileMetadataClick}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};
