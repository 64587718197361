import React, { useCallback, useEffect, useState } from "react";
import { CommonParamConfigurationSection } from "./retrieval-parameters-container";
import { useProjectSettings } from "../../../hooks";
import { EditSettingsButton } from "./EditSettingsButton";
import { useLinkClickHandler } from "react-router-dom";

interface Props {
  querySettings: IProjectRetrievalParametersSetting[];
  projectId: string;
}

const RETRIEVAL_PARAMETERS = "QS_RETRIEVAL_PARAM_";

export const RetrievalParameters = (props: Props) => {
  const { querySettings, projectId } = props;

  const { updateQuerySettings, updateSettingLoading } = useProjectSettings();

  const [isEdit, setIsEdit] = useState(false);
  const [localUpdatedParams, setLocalUpdatedParams] = useState([] as IProjectRetrievalParametersSetting[]);

  const editConfiguration = useCallback((obj: IProjectRetrievalParametersSetting) => {
    setLocalUpdatedParams((prevValue) =>
      prevValue.map((item) => {
        if (item.id === obj.id) return obj;
        return item;
      })
    );
  }, []);

  const setParams = useCallback(() => {
    if (!querySettings) {
      return;
    }
    setLocalUpdatedParams(querySettings);
  }, [querySettings]);

  useEffect(() => {
    setParams();
  }, [setParams]);

  const handleCancelClick = useCallback(() => {
    setIsEdit((prev) => !prev);
    setParams();
  }, [setParams]);

  const handleEditClick = useCallback(async () => {
    if (isEdit) {
      const settingArray: UpdateProjectQuerySetting[] = localUpdatedParams.map((item) => ({
        querySettingId: item.settingId,
        value: JSON.parse(item.value),
      }));
      const reqObj: UpdateProjectQuerySettingRequest = { settingArray };
      await updateQuerySettings(projectId, reqObj);
    }
    setIsEdit((prevValue) => !prevValue);
  }, [isEdit, localUpdatedParams, projectId, updateQuerySettings]);

  return (
    <div>
      <div className="grid grid-cols-2 gp m-t">
        {localUpdatedParams.map((item, index) => (
          <div key={`parameter-${index}`} className="box-group grid aic column-right-50">
            <CommonParamConfigurationSection
              isEdit={isEdit}
              editConfiguration={editConfiguration}
              param={item}
            />
          </div>
        ))}
      </div>
      <EditSettingsButton
        {...{
          handleEdit: handleEditClick,
          isEdit,
          setIsEdit,
          loading: updateSettingLoading,
          handleCancel: handleCancelClick,
        }}
      />
    </div>
  );
};
