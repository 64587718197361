import React, { useCallback, useEffect } from "react";
import { Route, Routes, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { PAGE_URL } from "../utils";
import * as Pages from "../pages";
import { updateProject } from "../store";
import { useDispatch } from "react-redux";
import { IOutletContext } from "./PrivateLayout";
import SingleDocQAListing from "../pages/dashboard/SingleDocQAListing";
import { TopicsService } from "../api";

const ProjectRoutes = () => {
  const { projectId } = useParams();
  const { setPageHeader, setLoadingByKey, loadingKeys, resetPageHeader } =
    useOutletContext<IOutletContext>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProject = useCallback(
    async (currentBgId: string) => {
      try {
        setLoadingByKey(loadingKeys.isPage, true);
        const currentProject = await TopicsService.getProjectById(currentBgId);
        dispatch(updateProject(currentProject));
        setPageHeader({
          pageRoute: PAGE_URL.DASHBOARD,
          pageTitle: currentProject.displayName,
          displayBackButton: true,
        });
      } catch (error: any) {
        console.error("Error fetching project by id: ", error.message);
        navigate(PAGE_URL.DASHBOARD);
      } finally {
        setLoadingByKey(loadingKeys.isPage, false);
      }
    },
    [projectId]
  );

  useEffect(() => {
    if (!projectId) {
      navigate(PAGE_URL.DASHBOARD);
    }

    fetchProject(projectId!);

    return () => resetPageHeader();
  }, [projectId]);

  return (
    <Routes>
      <Route path={`${PAGE_URL.DOCUMENTS}`} element={<SingleDocQAListing />} />
      <Route path={`${PAGE_URL.ASK_AUTO}/*`} element={<Pages.Dashboard />} />
      <Route path={PAGE_URL.DATASETS} element={<Pages.Datasets />} />
      <Route path={PAGE_URL.PROJECTS} element={<Pages.Settings />} />
      <Route path={PAGE_URL.SETTINGS} element={<Pages.ProjectsSettings />} />
    </Routes>
  );
};

export default ProjectRoutes;
