import { useCallback, useState } from "react";
import { ProjectsService, SettingsService } from "../api";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";
import { useAuthorization } from "./useAuthorization";

export const useFetchDocumentIngestionSettings = () => {
  const [isDropdownsDataLoading, setIsDropdownsDataLoading] = useState(false);
  const [chunkSizes, setChunkSizes] = useState([] as IDropdownOption[]);
  const [selectedChunkSize, setSelectedChunkSize] = useState({
    id: -1,
    value: "Select",
  } as IDropdownOption);
  const [tokenizers, setTokenizers] = useState([] as IDropdownOption[]);
  const [selectedTokenizer, setSelectedTokenizer] = useState({
    id: -1,
    value: "Select",
  } as IDropdownOption);
  const [embeddingModels, setEmbeddingModels] = useState([] as IDropdownOption[]);
  const [projectTypes, setProjectTypes] = useState([] as IDropdownOption[]);
  const [generativeAiModels, setGenerativeAiModels] = useState([] as IDropdownOption[]);
  const [selectedEmbeddingModel, setSelectedEmbeddingModel] = useState({
    id: -1,
    value: "Select",
  } as IDropdownOption);
  const { setIsAuthorized } = useAuthorization();

  const getDropdownsData = useCallback(
    async (chunkSize?: number, chunkingStrategy?: string, dataEmbedding?: string) => {
      setIsDropdownsDataLoading(true);
      try {
        const [{ data: chunkSizeData }, { data: tokenizersData }, { data: embeddingModelsData }] =
          await Promise.all([
            SettingsService.getChunkSizes(),
            SettingsService.getChunkingStrategy(),
            SettingsService.getEmbeddingModels(),
          ]);

        setChunkSizes(
          chunkSizeData.data.map((item) => {
            if (chunkSize) {
              if (item.value.toString() === chunkSize.toString()) {
                setSelectedChunkSize(item);
              }
            } else {
              const data = chunkSizeData.data.find((items: any) => items.isDefault);
              if (data) setSelectedChunkSize(data);
            }
            return {
              id: item.id,
              value: `${item.value}`,
              isDefault: item.isDefault,
            };
          })
        );
        setTokenizers(
          tokenizersData.data.map((item) => {
            if (item.value === chunkingStrategy) {
              if (item.id === chunkSize) {
                setSelectedTokenizer(item);
              }
            } else {
              const data = tokenizersData.data.find((items: any) => items.isDefault);
              if (data) setSelectedTokenizer(data);
            }
            return {
              id: item.id,
              value: `${item.value}`,
              isDefault: item.isDefault,
            };
          })
        );
        setEmbeddingModels(
          embeddingModelsData.data.map((item) => {
            if (item.value === dataEmbedding) {
              setSelectedEmbeddingModel(item);
            } else {
              const data = embeddingModelsData.data.find((items: any) => items.isDefault);
              if (data) setSelectedEmbeddingModel(data);
            }
            return {
              id: item.id,
              value: `${item.value}`,
              isDefault: item.isDefault,
            };
          })
        );
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", "Error fetching document ingestion settings", "error");
        }
      } finally {
        setIsDropdownsDataLoading(false);
      }
    },
    [setIsAuthorized]
  );

  const getProjectTypes = useCallback(async () => {
    try {
      const { data: projectTypesData } = await SettingsService.getProjectTypes();
      setProjectTypes(
        projectTypesData.data.map((item) => {
          return {
            id: item.id,
            value: `${item.name}`,
            settings: item.settings
          };
        })
      );
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsDropdownsDataLoading(false);
    }
  }, [setIsAuthorized]);

  const getGenerativeAiModel = useCallback(async () => {
    try {
      const { data: generativeAiModelsData } = await SettingsService.getGenerativeAiModel();
      setGenerativeAiModels(
        generativeAiModelsData.data.map((item) => {
          return {
            id: item.id,
            value: `${item.displayName}`,
          };
        })
      );
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsDropdownsDataLoading(false);
    }
  }, [setIsAuthorized]);

  return {
    getDropdownsData,
    getProjectTypes,
    getGenerativeAiModel,
    isDropdownsDataLoading,
    chunkSizes,
    tokenizers,
    embeddingModels,
    selectedChunkSize,
    setSelectedChunkSize,
    selectedEmbeddingModel,
    setSelectedEmbeddingModel,
    selectedTokenizer,
    setSelectedTokenizer,
    projectTypes,
    generativeAiModels
  };
};
