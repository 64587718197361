import React from "react";
import { Dropdown } from "../../../../components";
import { InformationIcon } from "../../../../assets/icons";
import { CHUNKING_STRATEGY_DESCRIPTION, CHUNK_SIZE_DESCRIPTION } from "../../../../utils";

interface Props {
  chunkSizes: IDropdownOption[];
  selectedChunkSize: IDropdownOption;
  setSelectedChunkSize: React.Dispatch<React.SetStateAction<IDropdownOption>>;
  tokenizers: IDropdownOption[];
  selectedTokenizer: IDropdownOption;
  setSelectedTokenizer: React.Dispatch<React.SetStateAction<IDropdownOption>>;
  isDisabled: boolean;
  isDropdownsDataLoading: boolean;
}

export const ChunkingStrategy = ({
  chunkSizes,
  selectedChunkSize,
  selectedTokenizer,
  setSelectedChunkSize,
  setSelectedTokenizer,
  tokenizers,
  isDisabled,
  isDropdownsDataLoading,
}: Props) => {
  const handleChunkSizeChange = (value: string, option: IDropdownOption) => {
    setSelectedChunkSize(option);
  };

  const handleTokenizerChange = (value: string, option: IDropdownOption) => {
    setSelectedTokenizer(option);
  };

  return (
    <div className="chunking-strategy white-box-bg">
      <h4>Chunking Strategy</h4>
      <div>
        <div className="flex gp aic">
          <p>Chunk Size</p>
          <div className="flex aic" title={CHUNK_SIZE_DESCRIPTION}>
            <InformationIcon />
          </div>
        </div>
        <Dropdown
          disabled={isDisabled}
          value={selectedChunkSize.value}
          options={chunkSizes.map((chunk) => ({ id: chunk.id, value: chunk.value , key: chunk.id}))}
          onSelect={handleChunkSizeChange}
          loading={isDropdownsDataLoading}
        />
      </div>
      <div>
        <div className="flex gp aic">
          <p>Chunking Strategy</p>
          <div className="flex aic" title={CHUNKING_STRATEGY_DESCRIPTION}>
            <InformationIcon />
          </div>
        </div>
        <Dropdown
          disabled={isDisabled}
          value={selectedTokenizer.value}
          options={tokenizers.map((tokenizer) => ({ id: tokenizer.id, value: tokenizer.value }))}
          onSelect={handleTokenizerChange}
          loading={isDropdownsDataLoading}
        />
      </div>
    </div>
  );
};
