import React from "react";
import { CautionIcon, HomeIcon } from "../../../assets/icons";
import { Button, Modal } from "antd";
import { NoProjectsWarningProps } from ".";

export const NoProjectsWarning = ({
  handleClose,
  handleOpenCreateProject,
  visibility,
}: NoProjectsWarningProps) => {
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closable={false}
    >
      <div className="addClient-content warning tc">
        <CautionIcon />
        <p className="font-Medium font-16 m-t">No projects exists in the system.</p>
        <p className="font-Medium font-16">Please create one to proceed.</p>
        <Button
          className="m-t fill"
          onClick={() => {
            handleClose();
            handleOpenCreateProject();
          }}
        >
          Create project
        </Button>
      </div>
    </Modal>
  );
};
