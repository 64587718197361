import { useCallback, useState } from "react";
import { TopicsService } from "../api/topic";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";
import { useAuthorization } from "./useAuthorization";
import { SettingsService } from "../api";

export const useProjectSettings = () => {
  const [loading, setLoading] = useState(false);
  const [updateSettingLoading, setUpdateSettingLoading] = useState(false);
  const [topicsSettings, setTopicSettings] = useState<ProjectDetailResponse>(
    {} as ProjectDetailResponse
  );

  const [documentIngestionSettings, setDocumentIngestionSettings] = useState({} as IProjectDocumentIngestionSetting);
  const [retrievalParametersSettings, setRetrievalParametersSettings] = useState([] as IProjectRetrievalParametersSetting[]);
  const [generativeAiParametersSettings, setGenerativeAiParametersSettings] = useState([] as ModelData[]);
  const [qaConfiguration, setQaConfiguration] = useState({} as IQAConfiguration);

  const { setIsAuthorized } = useAuthorization();

  const fetchSettings = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        const { data } = await TopicsService.getTopic(id);
        setTopicSettings(data.data);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", "Error feching project settings", "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );

  const fetchDocumentIngestionSettings = useCallback(
    async (uuid?: string) => {
      try {
        setLoading(true);
        const { data } = await SettingsService.getDocumentIngestionSettings(uuid);
        setDocumentIngestionSettings(data.data);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err?.response?.data?.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );

  const fetchRetrievalParametersSettings = useCallback(
    async (uuid?: string) => {
      try {
        setLoading(true);
        const { data } = await SettingsService.getRetrievalParametersSettings(uuid);
        setRetrievalParametersSettings(data.data);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err?.response?.data?.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );

  const fetchGenerativeAiParametersSettings = useCallback(
    async (uuid?: string) => {
      try {
        setLoading(true);
        const { data } = await SettingsService.getGenerativeAiParametersSettings(uuid);
        setGenerativeAiParametersSettings(data.data);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          //TODO uncomment after demo
          // openNotificationWithIcon("", err?.response?.data?.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );

  const fetchQAConfiguration = useCallback(
    async (uuid?: string) => {
      try {
        setLoading(true);
        const { data } = await SettingsService.getQAConfiguration(uuid);
        setQaConfiguration({...data.data});
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          //TODO uncomment after demo
          // openNotificationWithIcon("", err?.response?.data?.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );

  const updateDocumentIngestionSettings = useCallback(
    async (id: string, reqObj: UpdateProjectIngestionSettingRequest) => {
      try {
        setUpdateSettingLoading(true);
        const { data } = await TopicsService.updateProjectIngestionSetting(id, reqObj);
        openNotificationWithIcon(
          "",
          "Project document ingestion settings updated successfully",
          "success"
        );
        return data.data;
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setUpdateSettingLoading(false);
      }
    },
    []
  );

  const updateQuerySettings = useCallback(
    async (id: string, reqObj: any) => {
      try {
        setUpdateSettingLoading(true);
        const { data } = await TopicsService.updateProjectQuerySetting(id, reqObj);
        openNotificationWithIcon("", "Project query settings updated successfully", "success");
        return data.data;
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      } finally {
        setUpdateSettingLoading(false);
      }
    },
    []
  );

  return {
    fetchSettings,
    fetchDocumentIngestionSettings,
    fetchRetrievalParametersSettings,
    loading,
    topicsSettings,
    documentIngestionSettings,
    retrievalParametersSettings,
    updateDocumentIngestionSettings,
    updateSettingLoading,
    updateQuerySettings,
    generativeAiParametersSettings,
    fetchGenerativeAiParametersSettings,
    fetchQAConfiguration,
    qaConfiguration
  };
};
