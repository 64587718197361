import React, { useEffect } from "react";
import { PageWrapper } from "../../containers";
import SingleDocQAList from "../../containers/single-doc-qa-list/SingleDocQAList";
import { useProject } from "../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const SingleDocQAListing = () => {
  const { fetchProjects } = useProject();
  const { topicId } = useSelector((state: RootState) => state.fileFolderUpload);

  useEffect(() => {
    fetchProjects();
  }, [ topicId ]);

  return <PageWrapper pageContainerChildren={<SingleDocQAList />}/>;
};

export default SingleDocQAListing;
